/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* Defining a MambaEnum here instead of directly using the validation.InvalidImageReason enum to avoid pydantic validation failure `arbitrary_types_allowed`. */import {EnumMetadata} from '../metadata'

    /**
    * Defining a MambaEnum here instead of directly using the validation.InvalidImageReason enum to avoid pydantic validation failure `arbitrary_types_allowed`.
    */
export enum InvalidImageReason {
    LOW_RESOLUTION = 'LOW_RESOLUTION',
    BLURRY = 'BLURRY',
    UNSUPPORTED_FORMAT = 'UNSUPPORTED_FORMAT',
    UNKNOWN_FORMAT = 'UNKNOWN_FORMAT',
    FILE_TOO_LARGE = 'FILE_TOO_LARGE',
    INVALID_FILENAME = 'INVALID_FILENAME',
    MAXIMUM_SIZE_EXCEEDED = 'MAXIMUM_SIZE_EXCEEDED',
    UNKNOWN = 'UNKNOWN',
    UNPARSEABLE_MODEL_RESPONSE = 'UNPARSEABLE_MODEL_RESPONSE'
}


export const InvalidImageReasonMetadata: EnumMetadata<InvalidImageReason> = {
    name: 'InvalidImageReason',
    values: {
            LOW_RESOLUTION: 'LOW_RESOLUTION',
            BLURRY: 'BLURRY',
            UNSUPPORTED_FORMAT: 'UNSUPPORTED_FORMAT',
            UNKNOWN_FORMAT: 'UNKNOWN_FORMAT',
            FILE_TOO_LARGE: 'FILE_TOO_LARGE',
            INVALID_FILENAME: 'INVALID_FILENAME',
            MAXIMUM_SIZE_EXCEEDED: 'MAXIMUM_SIZE_EXCEEDED',
            UNKNOWN: 'UNKNOWN',
            UNPARSEABLE_MODEL_RESPONSE: 'UNPARSEABLE_MODEL_RESPONSE'
    }
}
