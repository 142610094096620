/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum Role {
    ADMIN = 'ADMIN',
    ATLAS_USER = 'ATLAS_USER',
    INTERNAL_USER = 'INTERNAL_USER',
    CREDENTIALING_ADVANCED = 'CREDENTIALING_ADVANCED',
    PHI_VIEWER = 'PHI_VIEWER',
    PHI_VIEWER_STANDARD = 'PHI_VIEWER_STANDARD',
    CLAIMS_ADVANCED = 'CLAIMS_ADVANCED',
    BENEFITS_ADVANCED = 'BENEFITS_ADVANCED',
    FINANCE_ADVANCED = 'FINANCE_ADVANCED',
    OPERATIONS_ADVANCED = 'OPERATIONS_ADVANCED',
    MEDICAL_RECORD_AUDITOR = 'MEDICAL_RECORD_AUDITOR',
    BULK_TAGGING_TOOL = 'BULK_TAGGING_TOOL',
    PAYER_LAUNCH_TOOL = 'PAYER_LAUNCH_TOOL',
    BRELLIUM_PILOT = 'BRELLIUM_PILOT',
    PROVIDER = 'PROVIDER',
    ZOCDOC = 'ZOCDOC',
    DOCASAP = 'DOCASAP',
    ZELIS_HCSC = 'ZELIS_HCSC',
    ML_SERVICE = 'ML_SERVICE',
    FOUNDRY = 'FOUNDRY',
    ZENDESK = 'ZENDESK',
    OKTA_SCIM = 'OKTA_SCIM',
    SYSTEM_USER = 'SYSTEM_USER',
    DEMO_USER = 'DEMO_USER',
    AUTH0_MACHINE_USER = 'AUTH0_MACHINE_USER',
    STEDI = 'STEDI',
    OUTERBOUNDS = 'OUTERBOUNDS',
    ENGINEER = 'ENGINEER',
    SECURITY = 'SECURITY',
    PRIVACY = 'PRIVACY',
    TSE = 'TSE',
    MARKETING = 'MARKETING',
    PATIENT = 'PATIENT',
    PATIENT_OPS_BULK_ACTIONS_USER = 'PATIENT_OPS_BULK_ACTIONS_USER',
    CARE_NAVIGATOR = 'CARE_NAVIGATOR',
    REFERRALS_ACCOUNT_HOLDER = 'REFERRALS_ACCOUNT_HOLDER',
    REFERRALS_ACCOUNT_MANAGER = 'REFERRALS_ACCOUNT_MANAGER',
    FAX_VIEWER = 'FAX_VIEWER',
    INTERNAL_MEDICAL_REFERRAL_AGENT = 'INTERNAL_MEDICAL_REFERRAL_AGENT',
    PATIENT_IMPERSONATOR = 'PATIENT_IMPERSONATOR',
    PROVIDER_IMPERSONATOR = 'PROVIDER_IMPERSONATOR',
    DEMO_USER_IMPERSONATOR = 'DEMO_USER_IMPERSONATOR',
    AUTOMATIC_PATIENT_IMPERSONATOR = 'AUTOMATIC_PATIENT_IMPERSONATOR',
    AUTOMATIC_PROVIDER_IMPERSONATOR = 'AUTOMATIC_PROVIDER_IMPERSONATOR',
    CONTEXTUAL_PATIENT_IMPERSONATOR = 'CONTEXTUAL_PATIENT_IMPERSONATOR',
    CONTEXTUAL_PROVIDER_IMPERSONATOR = 'CONTEXTUAL_PROVIDER_IMPERSONATOR',
    LAUNCH_PATIENT_IMPERSONATOR = 'LAUNCH_PATIENT_IMPERSONATOR',
    LAUNCH_PROVIDER_IMPERSONATOR = 'LAUNCH_PROVIDER_IMPERSONATOR',
    LIGHT_PATIENT_IMPERSONATOR = 'LIGHT_PATIENT_IMPERSONATOR',
    LIGHT_PROVIDER_IMPERSONATOR = 'LIGHT_PROVIDER_IMPERSONATOR',
    US_BASED_AGENT = 'US_BASED_AGENT',
    NON_US_BASED_AGENT = 'NON_US_BASED_AGENT',
    ADJUST_PROVIDER_PAYMENT_TOOL = 'ADJUST_PROVIDER_PAYMENT_TOOL',
    AI_PROGRESS_NOTE_AUDITOR = 'AI_PROGRESS_NOTE_AUDITOR'
}


export const RoleMetadata: EnumMetadata<Role> = {
    name: 'Role',
    values: {
            ADMIN: 'ADMIN',
            ATLAS_USER: 'ATLAS_USER',
            INTERNAL_USER: 'INTERNAL_USER',
            CREDENTIALING_ADVANCED: 'CREDENTIALING_ADVANCED',
            PHI_VIEWER: 'PHI_VIEWER',
            PHI_VIEWER_STANDARD: 'PHI_VIEWER_STANDARD',
            CLAIMS_ADVANCED: 'CLAIMS_ADVANCED',
            BENEFITS_ADVANCED: 'BENEFITS_ADVANCED',
            FINANCE_ADVANCED: 'FINANCE_ADVANCED',
            OPERATIONS_ADVANCED: 'OPERATIONS_ADVANCED',
            MEDICAL_RECORD_AUDITOR: 'MEDICAL_RECORD_AUDITOR',
            BULK_TAGGING_TOOL: 'BULK_TAGGING_TOOL',
            PAYER_LAUNCH_TOOL: 'PAYER_LAUNCH_TOOL',
            BRELLIUM_PILOT: 'BRELLIUM_PILOT',
            PROVIDER: 'PROVIDER',
            ZOCDOC: 'ZOCDOC',
            DOCASAP: 'DOCASAP',
            ZELIS_HCSC: 'ZELIS_HCSC',
            ML_SERVICE: 'ML_SERVICE',
            FOUNDRY: 'FOUNDRY',
            ZENDESK: 'ZENDESK',
            OKTA_SCIM: 'OKTA_SCIM',
            SYSTEM_USER: 'SYSTEM_USER',
            DEMO_USER: 'DEMO_USER',
            AUTH0_MACHINE_USER: 'AUTH0_MACHINE_USER',
            STEDI: 'STEDI',
            OUTERBOUNDS: 'OUTERBOUNDS',
            ENGINEER: 'ENGINEER',
            SECURITY: 'SECURITY',
            PRIVACY: 'PRIVACY',
            TSE: 'TSE',
            MARKETING: 'MARKETING',
            PATIENT: 'PATIENT',
            PATIENT_OPS_BULK_ACTIONS_USER: 'PATIENT_OPS_BULK_ACTIONS_USER',
            CARE_NAVIGATOR: 'CARE_NAVIGATOR',
            REFERRALS_ACCOUNT_HOLDER: 'REFERRALS_ACCOUNT_HOLDER',
            REFERRALS_ACCOUNT_MANAGER: 'REFERRALS_ACCOUNT_MANAGER',
            FAX_VIEWER: 'FAX_VIEWER',
            INTERNAL_MEDICAL_REFERRAL_AGENT: 'INTERNAL_MEDICAL_REFERRAL_AGENT',
            PATIENT_IMPERSONATOR: 'PATIENT_IMPERSONATOR',
            PROVIDER_IMPERSONATOR: 'PROVIDER_IMPERSONATOR',
            DEMO_USER_IMPERSONATOR: 'DEMO_USER_IMPERSONATOR',
            AUTOMATIC_PATIENT_IMPERSONATOR: 'AUTOMATIC_PATIENT_IMPERSONATOR',
            AUTOMATIC_PROVIDER_IMPERSONATOR: 'AUTOMATIC_PROVIDER_IMPERSONATOR',
            CONTEXTUAL_PATIENT_IMPERSONATOR: 'CONTEXTUAL_PATIENT_IMPERSONATOR',
            CONTEXTUAL_PROVIDER_IMPERSONATOR: 'CONTEXTUAL_PROVIDER_IMPERSONATOR',
            LAUNCH_PATIENT_IMPERSONATOR: 'LAUNCH_PATIENT_IMPERSONATOR',
            LAUNCH_PROVIDER_IMPERSONATOR: 'LAUNCH_PROVIDER_IMPERSONATOR',
            LIGHT_PATIENT_IMPERSONATOR: 'LIGHT_PATIENT_IMPERSONATOR',
            LIGHT_PROVIDER_IMPERSONATOR: 'LIGHT_PROVIDER_IMPERSONATOR',
            US_BASED_AGENT: 'US_BASED_AGENT',
            NON_US_BASED_AGENT: 'NON_US_BASED_AGENT',
            ADJUST_PROVIDER_PAYMENT_TOOL: 'ADJUST_PROVIDER_PAYMENT_TOOL',
            AI_PROGRESS_NOTE_AUDITOR: 'AI_PROGRESS_NOTE_AUDITOR'
    }
}
