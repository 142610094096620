import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { IAuthStore, IUiStore } from '@headway/shared/types/stores';
import { PerformLookupErrors } from '@headway/shared/utils/InsuranceLookupUtils';

export enum InsuranceLookupFormPage {
  PROVIDER_PROFILE = 'PROVIDER_PROFILE',
  CHECKOUT = 'CHECKOUT',
  ACCOUNT = 'ACCOUNT',
  BENEFITS_HUB = 'BENEFITS_HUB',
  REFERRED_PATIENT_ONBOARDING = 'REFERRED_PATIENT_ONBOARDING',
  HEALTHCARE_REFERRAL = 'HEALTHCARE_REFERRAL',
  CONTACT_FORM = 'CONTACT_FORM',
  SIGMUND_ADD_CLIENT_FLOW = 'SIGMUND_ADD_CLIENT_FLOW',
  SIGMUND_CLIENT_PAGE = 'SIGMUND_CLIENT_PAGE',
  SIGMUND_CALENDAR = 'SIGMUND_CALENDAR',
}

export enum InsuranceLookupInputMode {
  MANUAL = 'MANUAL',
  CAMERA = 'CAMERA', // TODO: PAT-799 delete when we delete camera flow
  UPLOAD = 'UPLOAD',
}

export enum SelectorError {
  CAMERA_PERMISSIONS_DENIED_ERROR = 'CAMERA_PERMISSIONS_DENIED_ERROR',
  CAMERA_GENERIC_ERROR = 'CAMERA_GENERIC_ERROR',
  UPLOAD_FILE_TOO_LARGE = 'UPLOAD_FILE_TOO_LARGE',
  UNPARSEABLE_IMAGE = 'UNPARSEABLE_IMAGE',
  PHOTO_UPLOAD_CATCHALL_ERROR = 'PHOTO_UPLOAD_CATCHALL_ERROR',
  CATCHALL_ERROR = 'CATCHALL_ERROR',
}

export type InsuranceCaptureProps = {
  AuthStore: IAuthStore;
  // if true, will show ocr experience, otherwise will show manual experience
  enableOcr: boolean;
  // should be true if rendering this form inside a modal, otherwise false
  isInsideModal?: boolean;
  cancelButtonText?: string;
  removeButtonText?: string;
  submitButtonText?: string;
  skipButtonText?: string;
  eligibilityPreCheck?: (
    formValues: Omit<InsuranceLookupFormValues, 'email'>
  ) => boolean;
  eligibilityPostCheck?: (
    values: Omit<InsuranceLookupFormValues, 'email'>,
    patientUser: UserRead | undefined
  ) => Promise<true>;
  providerId?: number;
  providerCarriers: ProviderFrontEndCarrierRead[];
  loadingOverride?: boolean;
  // if defined, will display a cancel button
  onCancel?: () => void;
  // if defined, will display a remove button
  onRemoveInsurance?: () => void;
  onError: (error: InsuranceLookupFormError) => void;
  onSuccess: (
    insurance: UserInsuranceRead,
    values: Omit<InsuranceLookupFormValues, 'email'>
  ) => void;
  showSecondaryLink?: boolean;
  UiStore: IUiStore;
  onSubmit?: () => void;
  // if defined, will display a skip button
  onSkip?: () => void;
  showInsuranceAuthorizationInstructions?: (
    carrier: FrontEndCarrierRead
  ) => void;
  // list of fecs to exclude from carrier dropdown
  frontEndCarriersExclusionList?: number[];
  // if defined, will display header information
  includeHeading?: boolean;
  // if defined, will display footer information (currently this says 'Most of our patients find their final cost matches our estimate.')
  includeFooter?: boolean;
  initialLookupErrorStates?: PerformLookupErrors;
  // we set this field if we want to fallback to the manual flow. this happens after there is an error previously, SO note that we do NOT track the add insurance started event in this case
  overrideAsManual?: boolean;
};

export type InsuranceLookupFormValues = {
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  dob: string;
  frontEndCarrierId: string;
  memberId: string;
  groupNumber?: string;
  lastSearchedState: UnitedStates;
  isMedicareOrMedicaid?: string;
};

export enum InsuranceLookupFormError {
  OUT_OF_NETWORK = 'OUT_OF_NETWORK',
  LOOKUP_FAILED = 'LOOKUP_FAILED',
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',
  VERIFY_FUZZY_MATCH = 'VERIFY_FUZZY_MATCH',
  MANUAL_VERIFICATION_REQUIRED = 'MANUAL_VERIFICATION_REQUIRED',
}
