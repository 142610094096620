/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { AllPaymentMethodsExhaustedRetryCycleMetadata } from './AllPaymentMethodsExhaustedRetryCycleMetadata';
import { ReadinessIssueImpact } from './ReadinessIssueImpact';
import {ModelMetadata} from '../metadata'

export interface PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle {
    impact: ReadinessIssueImpact;

    metadata: AllPaymentMethodsExhaustedRetryCycleMetadata;

    type: PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType;

}


    export enum PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType {
            ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE = 'ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE'
    }


export const PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleMetadata: ModelMetadata<PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle> = {
    name: 'PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle',
    properties: {
            impact: {
                title: 'Impact',
                type: 'ReadinessIssueImpact',
                
                isEnum: true
            },
            metadata: {
                title: 'Metadata',
                type: 'AllPaymentMethodsExhaustedRetryCycleMetadata',
                
                isEnum: false,
                isReference: true,
                referenceType: 'AllPaymentMethodsExhaustedRetryCycleMetadata'
            },
            type: {
                title: 'Type',
                type: 'string',
                
                isEnum: true
            },
    }
}
