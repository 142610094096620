import { ProviderSearchTopic } from '@headway/api/models/ProviderSearchTopic';

export const PROVIDER_PAGE_SIZE: number = 20;

/**
 * Options for day of week availability search
 */
export enum WeekdayFilter {
  WEEKDAY = 'weekday',
  WEEKEND = 'weekend',
}

export const PROVIDER_SEARCH_TOPIC_TO_SPECIALTY_KEY = {
  [ProviderSearchTopic.STRESS]: 'stress',
  [ProviderSearchTopic.FAMILY_STRUGGLES]: 'family',
  [ProviderSearchTopic.CAREER_ANXIETY]: 'anxiety',
  [ProviderSearchTopic.FEELING_DOWN_NO_MOTIVATION]: 'depression',
  [ProviderSearchTopic.STRUGGLE_WITH_FOCUS]: 'add_adhd',
  [ProviderSearchTopic.RECENT_LOSS]: 'bereavement',
  [ProviderSearchTopic.GENDER_SEXUAL_IDENTITY]: 'lgbtq',
  [ProviderSearchTopic.DIVORCE_SEPARATION]: 'relationships',
  [ProviderSearchTopic.RELATIONSHIPS]: 'relationships',
  [ProviderSearchTopic.RECENT_CHILD]: 'maternal',
  [ProviderSearchTopic.CHALLENGES_WITH_FOOD]: 'eating_disorders',
};
