/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enum to indicate a provider\'s ability to perform talk therapy, medication management, or both. */import {EnumMetadata} from '../metadata'

    /**
    * An enum to indicate a provider\'s ability to perform talk therapy, medication management, or both.
    */
export enum ProviderFunction {
    TALK_THERAPY = 'Talk Therapy',
    MEDICATION_MANAGEMENT = 'Medication Management',
    TALK_THERAPY_AND_MEDICATION_MANAGEMENT = 'Therapy and Medication Management'
}


export const ProviderFunctionMetadata: EnumMetadata<ProviderFunction> = {
    name: 'ProviderFunction',
    values: {
            TALK_THERAPY: 'Talk Therapy',
            MEDICATION_MANAGEMENT: 'Medication Management',
            TALK_THERAPY_AND_MEDICATION_MANAGEMENT: 'Therapy and Medication Management'
    }
}
