/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum ProviderEventType {
    APPOINTMENT = 'APPOINTMENT',
    INTAKE_CALL = 'INTAKE_CALL',
    AVAILABILITY = 'AVAILABILITY',
    FREE = 'FREE',
    BUSY = 'BUSY'
}


export const ProviderEventTypeMetadata: EnumMetadata<ProviderEventType> = {
    name: 'ProviderEventType',
    values: {
            APPOINTMENT: 'APPOINTMENT',
            INTAKE_CALL: 'INTAKE_CALL',
            AVAILABILITY: 'AVAILABILITY',
            FREE: 'FREE',
            BUSY: 'BUSY'
    }
}
