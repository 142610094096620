import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import React from 'react';
import Script from 'react-load-script';

import { ZendeskApi } from '@headway/api/resources/ZendeskApi';
import { theme } from '@headway/helix/theme';

// Utility function to check if Zendesk widget API is available
function isZendeskWidgetAvailable() {
  return (
    typeof window !== 'undefined' &&
    window.zE &&
    typeof window.zE === 'function'
  );
}

function showWidget() {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'show');
    }
  } catch (error) {
    console.warn('Zendesk webWidget.show method not available', error);
  }
}

function hideWidget() {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'hide');
    }
  } catch (error) {
    console.warn('Zendesk webWidget.hide method not available', error);
  }
}

function addWidgetOnCloseCallback(callback) {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget:on', 'close', callback);
    }
  } catch (error) {
    console.warn('Zendesk webWidget:on close method not available', error);
  }
}

function addWidgetOnChatStartCallback(callback) {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget:on', 'chat:start', callback);
    }
  } catch (error) {
    console.warn('Zendesk webWidget:on chat:start method not available', error);
  }
}

function addWidgetOnChatEndCallback(callback) {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget:on', 'chat:end', callback);
    }
  } catch (error) {
    console.warn('Zendesk webWidget:on chat:end method not available', error);
  }
}

function addWidgetOnUnreadMessageCallback(callback) {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget:on', 'chat:unreadMessages', callback);
    }
  } catch (error) {
    console.warn(
      'Zendesk webWidget:on chat:unreadMessages method not available',
      error
    );
  }
}

function openWidget() {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'open');
    }
  } catch (error) {
    console.warn('Zendesk webWidget.open method not available', error);
  }
}

function closeWidget() {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'close');
    }
  } catch (error) {
    console.warn('Zendesk webWidget.close method not available', error);
  }
}

function resetWidget() {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'reset');
    }
  } catch (error) {
    console.warn('Zendesk webWidget.reset method not available', error);
  }
}

function identifyWidget(name, email) {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'identify', {
        name,
        email,
      });
    }
  } catch (error) {
    console.warn('Zendesk webWidget.identify method not available', error);
  }
}

function updateWidgetSettings(settings) {
  try {
    if (isZendeskWidgetAvailable()) {
      zE('webWidget', 'updateSettings', settings);
    }
  } catch (error) {
    console.warn(
      'Zendesk webWidget.updateSettings method not available',
      error
    );
  }
}

export class ZendeskWidget extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    try {
      // Only set zESettings if we're using the classic widget, not messaging
      if (typeof window !== 'undefined') {
        window.zESettings = merge(
          this.getDefaultSettings(),
          this.props.settings
        );
      }
    } catch (error) {
      console.warn('Error setting up Zendesk widget settings:', error);
    }
  }

  getDefaultSettings = () => {
    return {
      webWidget: {
        chat: {
          menuOptions: { emailTranscript: false },
        },
        authenticate: {
          chat: {
            jwtFn: (callback) => ZendeskApi.getToken().then(callback),
          },
        },
        launcher: {
          label: {
            '*': 'Contact us',
          },
        },
        color: {
          theme: theme.color.system.black,
          launcher: theme.color.system.black,
          launcherText: theme.color.system.white,
          button: theme.color.system.black,
          resultLists: theme.color.system.black,
          header: theme.color.system.black,
          articleLinks: theme.color.system.textBlack,
        },
        zIndex: 15, // below any layered Material UI elements https://material-ui.com/customization/z-index
        // also below Helix components due to chat hiding functional components https://therapymatch.slack.com/archives/C06UJSJJH2N/p1728579084756149
      },
    };
  };

  handleLoad = () => {
    this.setState({ isLoading: false }, () => {
      try {
        // Check if we're using Zendesk messaging or the classic widget
        if (!isZendeskWidgetAvailable()) {
          console.warn(
            'Zendesk webWidget API not available. You may be using Zendesk messaging instead of the classic widget.'
          );
          return;
        }

        this.updateWidget();
        addWidgetOnCloseCallback(this.onClose);
        addWidgetOnChatStartCallback(this.onChatStart);
        addWidgetOnChatEndCallback(this.onChatEnd);
        addWidgetOnUnreadMessageCallback(this.onUnreadMessage);
      } catch (error) {
        console.warn('Error setting up Zendesk widget callbacks:', error);
      }
    });
  };

  onClose = () => {
    if (this.props.onWidgetClose) {
      this.props.onWidgetClose();
    }

    closeWidget();
  };

  onChatStart = () => {
    if (this.props.onChatStart) {
      this.props.onChatStart();
    }
  };

  onChatEnd = () => {
    if (this.props.onChatEnd) {
      this.props.onChatEnd();
    }
    setTimeout(this.onClose, 1000 * 60 * 60 * 3); // 3 hours
  };

  onUnreadMessage = () => {
    if (this.props.onUnreadMessage) {
      this.props.onUnreadMessage();
    }
  };

  updateWidget = () => {
    const { isLoading } = this.state;
    const { name, email, hideWidgetLauncher, widgetOpen, settings } =
      this.props;

    // only call widget functions if it is loaded
    if (isLoading) {
      return;
    }

    try {
      if (name && email) {
        identifyWidget(name, email);
      } else {
        resetWidget();
      }

      // hide or show the launcher if it's controlled
      if (hideWidgetLauncher !== undefined) {
        if (hideWidgetLauncher) {
          hideWidget();
        } else {
          showWidget();
        }
      }

      // hide or show the widget if it's controlled
      if (widgetOpen !== undefined) {
        if (widgetOpen) {
          showWidget();
          openWidget();
        } else {
          closeWidget();
          hideWidget();
        }
      }

      updateWidgetSettings(merge(this.getDefaultSettings(), settings));
    } catch (error) {
      console.warn('Error updating Zendesk widget:', error);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.name !== prevProps.name ||
      this.props.email !== prevProps.email ||
      this.props.hideWidgetLauncher !== prevProps.hideWidgetLauncher ||
      this.props.widgetOpen !== prevProps.widgetOpen ||
      !isEqual(this.props.settings, prevProps.settings)
    ) {
      this.updateWidget();
    }
  }

  render() {
    return (
      <Script
        attributes={{ id: 'ze-snippet' }}
        url={`https://static.zdassets.com/ekr/snippet.js?key=${this.props.apiKey}`}
        onLoad={this.handleLoad}
      />
    );
  }
}
