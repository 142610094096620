/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserSessionLocationPreference {
    IN_PERSON = 'IN_PERSON',
    VIRTUAL = 'VIRTUAL',
    VIRTUAL_FOR_NOW = 'VIRTUAL_FOR_NOW',
    NO_PREFERENCE = 'NO_PREFERENCE'
}


export const UserSessionLocationPreferenceMetadata: EnumMetadata<UserSessionLocationPreference> = {
    name: 'UserSessionLocationPreference',
    values: {
            IN_PERSON: 'IN_PERSON',
            VIRTUAL: 'VIRTUAL',
            VIRTUAL_FOR_NOW: 'VIRTUAL_FOR_NOW',
            NO_PREFERENCE: 'NO_PREFERENCE'
    }
}
