/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum TimeOfDayFilter {
    MORNING = 'MORNING',
    NOON = 'NOON',
    AFTERNOON = 'AFTERNOON',
    EVENING = 'EVENING'
}


export const TimeOfDayFilterMetadata: EnumMetadata<TimeOfDayFilter> = {
    name: 'TimeOfDayFilter',
    values: {
            MORNING: 'MORNING',
            NOON: 'NOON',
            AFTERNOON: 'AFTERNOON',
            EVENING: 'EVENING'
    }
}
