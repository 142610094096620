import { mergeProps, useSlotId } from '@react-aria/utils';
import React from 'react';
import { useCheckboxGroup } from 'react-aria';
import { CheckboxGroupState, useCheckboxGroupState } from 'react-stately';

import { DATA } from './consts';
import { FieldError, FieldRoot } from './fields';
import { Validator } from './forms';
import { theme } from './theme';
import { useFieldValidity } from './useFieldValidity';
import { FormInputProps, useFormInput } from './useFormInput';

export const CheckboxGroupContext =
  React.createContext<CheckboxGroupState | null>(null);

type CheckboxGroupProps = {
  children: React.ReactNode;
  optionalityText?: React.ReactNode;
  size?: 'small' | 'large';
  constraints?: Validator<string[]>[];
} & FormInputProps<string[]>;

export const CheckboxGroup = ({
  size = 'small',
  ...props
}: CheckboxGroupProps) => {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { ariaProps } = useFormInput(props);

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      rootRef.current?.focus();
    },
  });

  const options = mergeProps(ariaProps, validationProps);

  const cbgState = useCheckboxGroupState(options);
  const {
    groupProps,
    labelProps,
    isInvalid,
    validationErrors,
    errorMessageProps,
  } = useCheckboxGroup(options, cbgState);

  const descriptionId = useSlotId([Boolean(props.helpText), props.validation]);

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  return (
    <FieldRoot
      ref={rootRef}
      className="hlx-checkbox-group-root"
      isDisabled={props.disabled}
      isInvalid={isInvalid}
      isReadonly={props.readonly}
      {...mergeProps(groupProps, fieldRootProps, {
        'aria-describedby':
          [
            descriptionId,
            errorMessageProps?.id,
            optionalityId,
            props['aria-describedby'],
          ]
            .filter(Boolean)
            .join(' ') || undefined,
        'data-hlx-size': size,
      })}
    >
      <div className="hlx-checkbox-group-descriptors">
        <span className="hlx-checkbox-group-label" {...labelProps}>
          {props.label}
        </span>
        {props.optionalityText && (
          <span
            id={optionalityId}
            className="hlx-checkbox-group-optionality-text"
          >
            {props.optionalityText}
          </span>
        )}
      </div>
      <div className="hlx-checkbox-group-controls">
        <CheckboxGroupContext.Provider value={cbgState}>
          {props.children}
        </CheckboxGroupContext.Provider>
      </div>

      {props.helpText && (
        <div className="hlx-checkbox-group-help-text" id={descriptionId}>
          {props.helpText}
        </div>
      )}
      <FieldError
        className="hlx-checkbox-group-error"
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        {...errorMessageProps}
      />
    </FieldRoot>
  );
};
