import { AxiosRequestConfig } from 'axios';
import React from 'react';

import { axios as axiosClient } from '@headway/api/axios.config';
import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import { ContentText } from '@headway/helix/ContentText';
import { REFERRAL_SOURCE } from '@headway/shared/constants/referrals';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import useScript from '@headway/shared/utils/useScript';

const APP_ENV =
  process.env.REACT_APP_ENVIRONMENT ?? process.env.NEXT_PUBLIC_ENVIRONMENT;

export const useReferralProgramIncentiveAmount = () => {
  const query = useExtoleUserQuery();
  return query.data?.advocateReward ?? '$350';
};

export const useReferredBonusAmount = () => {
  const query = useExtoleUserQuery();
  return query.data?.friendReward ?? '$100';
};

const EXTOLE_DOMAIN = 'https://share.findheadway.com';

interface ExtoleUserData {
  email?: string;
  first_name?: string;
  last_name?: string;
  /**
   * The user's unique identifier in our system.  This is how Extole will relate their
   * user back to Headway's user.
   */
  partner_user_id: string;
}

function useExtole() {
  const scriptStatus = useScript(`${EXTOLE_DOMAIN}/core.js`);

  if (scriptStatus === 'error') {
    throw new Error('Error loading Extole');
  }

  // @ts-expect-error
  return scriptStatus === 'ready' ? window.extole : null;
}

interface ExtoleOptions {
  name: string;
  element: HTMLElement;
  jwt?: string;
  data:
    | {
        container?: 'test';
      }
    | (ExtoleUserData & { container?: 'test' });
}
/**
 * Returns a callback that can be used to track a lead form conversion in Extole.
 */
export function useExtoleLeadFormConversion(
  env: string = 'test'
): (data: ExtoleOptions['data']) => void {
  const extole = useExtole();

  return React.useCallback(
    (data) => {
      if (!extole) {
        return;
      }

      if (env !== 'production') {
        data.container = 'test';
      }
      extole.createZone({
        name: 'lead_form_submitted',
        data: data,
      });
    },
    [extole]
  );
}

/**
 * Returns a ref that can be attached to an element that you'd like to embed an Extole zone in.
 * If a token is provided, it will be used to authenticate the user with Extole.
 */
const useExtoleEmbed = (opts: {
  name: string;
  element?: HTMLElement;
  element_id?: string;
  jwt?: string;
  data: Record<string, unknown> & { labels?: string };
}): React.RefObject<HTMLDivElement> => {
  const extoleZoneRef = React.useRef<HTMLDivElement | null>(null);

  const extole = useExtole();

  React.useEffect(() => {
    if (!extole || !extoleZoneRef.current) {
      return;
    }

    (function (c, b, f, k, a) {
      // @ts-expect-error
      c[b] = c[b] || {};
      // @ts-expect-error
      for (c[b].q = c[b].q || []; a < k.length; ) f(k[a++], c[b]);
    })(
      window,
      'extole',
      // @ts-expect-error
      function (c, b) {
        b[c] =
          b[c] ||
          function () {
            b.q.push([c, arguments]);
          };
      },
      ['createZone'],
      0
    );

    opts.element = extoleZoneRef.current;

    if (APP_ENV !== 'production') {
      opts.data.container = 'test';
    }

    extole.createZone(opts);
  }, [extole]);

  return extoleZoneRef;
};

export const useExtoleShareEmbedded = ({
  token,
  userReferralDetails,
  programLabel,
}: {
  token: string;
  userReferralDetails: ExtoleUserResponse;
  programLabel?: string;
}) => {
  const env = APP_ENV !== 'production' ? 'staging' : '';
  const opts = {
    name: 'referral_page',
    element_id: 'extole_zone_referral_page',
    jwt: token,
    data: {
      email: userReferralDetails.email,
      first_name: userReferralDetails.first_name,
      last_name: userReferralDetails.last_name,
      partner_user_id: userReferralDetails.partner_user_id,
      environment: env,
      labels: programLabel,
    },
  };
  return useExtoleEmbed(opts);
};

export interface ExtoleFriend {
  firstNameOrEmail: string;
  initials: string;
  email: string;
  profilePictureUrl: string;
  status: string;
  referralDate: string;
  statusDate: string;
}

interface ExtoleReward {
  rewardId: string;
  partnerRewardId: string;
  faceValue: string;
  faceValueType: string;
  dateEarned: string;
  rewardType: string;
  partnerRewardSupplierId: string;
  state: string;
}
export interface ExtoleUserResponse extends ExtoleUserData {
  profile_picture_url?: string;
  shareable_link: string;
  advocate_code: string;
  rewards: ExtoleReward[];
  friends: ExtoleFriend[];
  totalReferrals: number;
  totalSignups: number;
  totalEarned: number;
}

export interface IEmailDetails {
  subject: string;
  message: string;
}
export interface ExtoleDataResponse {
  advocateReward: string;
  friendReward: string;
  program_label: string;
  campaign_id: string;
  links: {
    company_url: string;
    terms_url: string;
    how_it_works_url: string;
  };
  sharing: {
    email: IEmailDetails;
    native: {
      message: string;
    };
    sms: {
      message: string;
    };
  };
  calls_to_action: {
    account_page: {
      message: string;
    };
    confirmation: {
      message: string;
    };
    menu: {
      message: string;
    };
    product: {
      message: string;
    };
  };
  me: ExtoleUserResponse;
}
export interface ExtoleResponse {
  event_id: string;
  campaign_id: string;
  data: ExtoleDataResponse;
}

/**
 * Sigmund uses this shared hook for the referral page.
 * But at the time of this writing, Sigmund axios requests are
 * augmented with Auth0 auth tokens, which overwrites the Extole token.
 * To remedy this, we need to transform the request to remove Sigmund's
 * auth token before adding the Extole token in the header.
 */
const transformRequest = (
  data: AxiosRequestConfig['data'],
  headers: AxiosRequestConfig['headers'] = {},
  headersToSet: Record<string, string> = {},
  stringify: boolean = true
) => {
  delete headers['Authorization'];
  for (const header in headersToSet) {
    headers[header] = headersToSet[header];
  }

  if (stringify) {
    return JSON.stringify(data);
  }
  return data;
};

export const fetchExtoleUserData = async (
  token: string,
  programLabel?: string
): Promise<ExtoleResponse> => {
  const response = await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v6/zones`,
    { event_name: 'advocate_mobile_experience' },
    {
      params: { programLabel },
      transformRequest: (
        data: AxiosRequestConfig['data'],
        headers: AxiosRequestConfig['headers']
      ) => {
        return transformRequest(data, headers, {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        });
      },
    }
  );
  return response.data;
};

interface ExtoleTokenResponse {
  access_token: string;
  expires_in: number;
  scopes: [string];
  capabilities: [string];
}

export const createOrFetchExtoleToken = async ({
  user,
  jwt,
}: {
  user?: UserRead;
  jwt?: string;
}): Promise<ExtoleTokenResponse> => {
  // for signed in user
  if (user?.email) {
    const response = await axiosClient.post(
      `${EXTOLE_DOMAIN}/api/v5/token`,
      { jwt },
      {
        transformRequest: (
          data: AxiosRequestConfig['data'],
          headers: AxiosRequestConfig['headers']
        ) => {
          return transformRequest(data, headers, {
            Authorization: `Bearer ${jwt}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          });
        },
      }
    );
    return response.data;
  }

  // for anons
  const response = await axiosClient.get(`${EXTOLE_DOMAIN}/api/v4/token`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const _postEvent = async ({
  token,
  data,
}: {
  token: string;
  data: Record<string, unknown>;
}) => {
  const response = await axiosClient.post(`${EXTOLE_DOMAIN}/events`, data, {
    transformRequest: (
      data: AxiosRequestConfig['data'],
      headers: AxiosRequestConfig['headers']
    ) => {
      return transformRequest(data, headers, {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      });
    },
  });
  return response.data;
};

export const postShareEvent = async ({
  token,
  advocateCode,
  email,
}: {
  token: string;
  advocateCode: string;
  email: string;
}) => {
  const data = {
    event_name: 'share',
    data: {
      channel: 'LEAD_FORM',
      'share.advocate_code': advocateCode,
      'share.recipient': email,
    },
  };

  return await _postEvent({ token, data });
};

export const postReferProviderEvent = async ({
  token,
  advocateCode,
  email,
  firstName,
  lastName,
  phoneNumber,
  licenseType,
  licenseState,
  campaignId,
  programLabel,
  advocateFirstName,
  advocateLastName,
}: {
  token: string;
  advocateCode: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  licenseType: string;
  licenseState: string;
  campaignId: string;
  programLabel?: string;
  advocateFirstName: string;
  advocateLastName: string;
}) => {
  const data = {
    event_name: 'provider_created',
    data: {
      advocatesCode: advocateCode,
      'friend.email': email,
      'friend.first_name': firstName,
      'friend.last_name': lastName,
      'friend.license_state': licenseState, // state abreviation
      'friend.license_type': licenseType,
      'friend.phone': phoneNumber, // '(800) 756-3421',
      provider_email: email,
      provider_first_name: firstName,
      provider_last_name: lastName,
      provider_license_state: licenseState,
      provider_license_type: licenseType,
      provider_phone: phoneNumber,
      source: 'invite_a_provider:lead_form',
      target: campaignId,
      labels: programLabel,
      first_name: advocateFirstName,
      last_name: advocateLastName,
    },
  };
  if (programLabel) {
    data.data.labels = programLabel;
  }
  return await _postEvent({ token, data });
};

export const sendReferralEmail = async ({
  token,
  advocateCode,
  email,
  message,
  campaignId,
}: {
  advocateCode: string;
  token: string;
  email: string;
  message?: string;
  campaignId?: string;
}) => {
  const data = {
    subject: '',
    advocate_code: advocateCode,
    data: {
      channel: 'EMAIL',
      target: campaignId,
    },
    message,
    recipient_emails: [email],
  };

  const response = await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v6/email/share/advocate-code/batch`,
    data,
    {
      transformRequest: (
        data: AxiosRequestConfig['data'],
        headers: AxiosRequestConfig['headers']
      ) => {
        return transformRequest(data, headers, {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        });
      },
    }
  );
  return response.data;
};

export const trackCTAEvents = async ({
  eventName,
  token,
  source,
  referralId,
  channel,
}: {
  eventName: string;
  token: string;
  source?: string; // where this event is being called
  channel?: 'email' | 'direct' | 'sms' | 'link';
  referralId?: string;
}) => {
  const response = await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v6/events`,
    {
      event_name: eventName,
      data: {
        source: source ? `${source}:${REFERRAL_SOURCE}` : REFERRAL_SOURCE,
        'share.channel': channel,
        partner_share_id: referralId,
      },
    },
    {
      transformRequest: (
        data: AxiosRequestConfig['data'],
        headers: AxiosRequestConfig['headers']
      ) => {
        return transformRequest(data, headers, {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        });
      },
    }
  );
  return response.data;
};

export const consentToMarketing = async ({
  token,
  consent,
}: {
  token: string;
  consent: boolean;
}) => {
  await axiosClient.post(
    `${EXTOLE_DOMAIN}/api/v4/me/parameters`,
    `::headers.x-extole-app=javascript_sdk&type=PRIVATE&parameters.optin=${consent}`,
    {
      transformRequest: (
        data: AxiosRequestConfig['data'],
        headers: AxiosRequestConfig['headers']
      ) => {
        return transformRequest(
          data,
          headers,
          {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          false
        );
      },
    }
  );
};

export const useExtoleUserQuery = (programLabel?: string) => {
  const token = useExtoleAccessToken();

  const query = useQuery(
    ['userReferralData', token],
    async () => {
      const response = await fetchExtoleUserData(token!, programLabel);
      return response?.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      enabled: Boolean(token),
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

const ExtoleAccessTokenContext = React.createContext<string | undefined>(
  undefined
);

export const useExtoleAccessToken = () => {
  const context = React.useContext(ExtoleAccessTokenContext);

  return context;
};

interface ExtoleAccessTokenProviderProps {
  children: React.ReactNode;
  user: UserRead;
}

export const ExtoleAccessTokenProvider = ({
  children,
  user,
}: ExtoleAccessTokenProviderProps) => {
  const referralPortalQuery = useQuery(
    ['referralPortal', user?.id],
    () => UserApi.getReferralPortal(user?.id),
    {
      enabled: Boolean(user?.id),
      refetchOnWindowFocus: false,
    }
  );
  const extoleJwt = referralPortalQuery.data?.accessToken;

  const extoleAccessTokenQuery = useQuery(
    ['extoleAccessToken', extoleJwt],
    async () => await createOrFetchExtoleToken({ user, jwt: extoleJwt }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const token = extoleAccessTokenQuery.data?.access_token;

  return (
    <ExtoleAccessTokenContext.Provider value={token ?? undefined}>
      {children}
    </ExtoleAccessTokenContext.Provider>
  );
};

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    logException(error, {
      contexts: { react: { componentStack: info.componentStack } },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ExtoleError />;
    }

    return this.props.children;
  }
}

const ExtoleError = () => {
  return (
    <div className="flex min-h-screen">
      <div className="mx-10 my-10">
        <h1>
          <ContentText variant="page-title">Something went wrong</ContentText>
        </h1>
        <p className="max-w-prose">
          <ContentText>
            We're unable to load the page you're looking for at the moment.
            <br />
            Our referral program relies on a 3rd party service that may be
            blocked by your browser's privacy settings. Please{' '}
            <strong>disable any ad blockers or privacy extensions</strong> and
            try again.
            <br />
            <br />
            If this page still does not load, please try again later. This error
            has been logged and we're working to fix it.
          </ContentText>
        </p>
      </div>
    </div>
  );
};
