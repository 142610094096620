import { useEffectEvent } from '@react-aria/utils';
import { HelpTextProps } from '@react-types/shared';
import {
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFocus, useFocusWithin } from 'react-aria';
import { flushSync } from 'react-dom';

import { ValidationState, Validator } from './forms';

/**
 * Manage the validity of a field, implementing the same logic as HTML constraints
 * and the CSS :user-invalid pseudo-class. That is, the field is only marked as
 * invalid if any of the following conditions are met:
 *
 * - The user has interacted with the field (focus, change, blur)
 * - The user has attempted to submit the form
 * - The field was already invalid when it gained focus
 *
 * If the control has focus, and the value was invalid when it gained focus,
 * re-validate on every keystroke. The result is that if the control was valid
 * when the user started interacting with it, the validity styling is changed only
 * when the user shifts focus to another control. However, if the user is trying
 * to correct a previously-flagged value, the control shows immediately
 * when the value becomes valid.
 */

interface ValidationProps<T> {
  validation: ValidationState | undefined;
  constraints: Validator<T>[] | undefined;
  /**
   * A ref to the root element of the field. This is used to determine if the field
   *
   */
  ref: React.RefObject<Element>;
  onCheckValidity: () => boolean;
  focus: () => void;
}

type RootFocusProps = ReturnType<typeof useFocusWithin>['focusWithinProps'];

type FieldValidityProps<T> = {
  isInvalid: boolean | undefined;
  errorMessage: HelpTextProps['errorMessage'];
  validationBehavior: 'aria';
  validate: undefined | ((value: T) => string | null | undefined);
  fieldRootProps: {
    /**
     * Focus handler for the root element of the field.
     */
    onFocus?: RootFocusProps['onFocus'];
    /**
     * Blur handler for the root element of the field.
     */
    onBlur?: RootFocusProps['onBlur'];
  };
};

export function useFieldValidity<T>({
  validation,
  constraints,
  ref,
  onCheckValidity,
  focus,
}: ValidationProps<T>): FieldValidityProps<T> {
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const { focusWithinProps } = useFocusWithin({
    onBlurWithin() {
      setUserHasInteracted(true);
    },
  });

  /**
   * We wrap the onCheckValidity callback in a useEffectEvent to ensure that
   * the callback has access to the latest state/props when it is called.
   */
  const checkValidity = useEffectEvent(onCheckValidity);

  useEffect(() => {
    const root = ref.current;

    if (!root) {
      return;
    }

    const form = root.closest('form');

    if (!form) {
      return;
    }

    const onSubmit = (event: SubmitEvent) => {
      flushSync(() => {
        setUserHasInteracted(true);
      });

      if (checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();

        const firstInvalidFieldRoot = form.querySelector(
          '.hlx-field-root[data-hlx-validation="invalid"]'
        );

        if (firstInvalidFieldRoot?.contains(root)) {
          focus();
        }
      }
    };

    form.addEventListener('submit', onSubmit);
    return () => {
      if (form) {
        form.removeEventListener('submit', onSubmit);
      }
    };
  }, [ref, onCheckValidity, focus]);

  return {
    /**
     * If the validation prop is explicitly set, use that value. Otherwise, let react-aria
     * handle the validation state based on the constraints and user interaction.
     */
    isInvalid: validation?.validity === 'invalid' ? true : undefined,
    errorMessage:
      validation?.validity === 'invalid'
        ? validation.message
        : (v) => {
            if (!userHasInteracted) {
              return undefined;
            }

            return v.validationErrors[0];
          },
    validationBehavior: 'aria' as const,
    validate:
      constraints && constraints?.length > 0
        ? (value: T) => {
            if (!userHasInteracted) {
              return null;
            }

            for (const constraint of constraints) {
              const result = constraint(value);

              if (result !== null) {
                return result;
              }
            }

            return null;
          }
        : undefined,
    fieldRootProps: focusWithinProps,
  };
}
