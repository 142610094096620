import { AccountBalance, CreditCard } from '@mui/icons-material';
import React from 'react';

import { BankAccountVerificationStatus } from '@headway/api/models/BankAccountVerificationStatus';
import { PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType } from '@headway/api/models/PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle';
import { PaymentMethodReadinessIssueBankAccountUnverifiedType } from '@headway/api/models/PaymentMethodReadinessIssueBankAccountUnverified';
import { PaymentMethodReadinessIssueCreditCardExpiredType } from '@headway/api/models/PaymentMethodReadinessIssueCreditCardExpired';
import { PaymentMethodReadinessIssueMissingType } from '@headway/api/models/PaymentMethodReadinessIssueMissing';
import { PaymentMethodReadinessIssuePreAuthChargeFailedType } from '@headway/api/models/PaymentMethodReadinessIssuePreAuthChargeFailed';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';
import { Badge } from '@headway/helix/Badge';
import { ContentText } from '@headway/helix/ContentText';
import { IconCheckCircle } from '@headway/helix/icons/CheckCircle';
import { IconWarning } from '@headway/helix/icons/Warning';
import { IconWarningCircle } from '@headway/helix/icons/WarningCircle';
import { theme } from '@headway/helix/theme';
import { Amex } from '@headway/icons/dist/Amex';
import { Discover } from '@headway/icons/dist/Discover';
import { Mastercard } from '@headway/icons/dist/Mastercard';
import { Visa } from '@headway/icons/dist/Visa';
import { useAppointmentReadiness } from '@headway/shared/hooks/useAppointmentReadiness';
import {
  getPaymentMethodStatus,
  PaymentMethodStatus,
} from '@headway/shared/utils/userPaymentMethods';
import { theme as legacyTheme } from '@headway/ui/theme';

const CreditCardBrandIcon = ({ brand }: { brand?: string }) => {
  switch (brand) {
    case 'American Express':
      return <Amex aria-label="American Express" height={24} width={36} />;
    case 'Visa':
      return <Visa aria-label="Visa" height={24} width={36} />;
    case 'MasterCard':
      return <Mastercard aria-label="MasterCard" height={24} width={36} />;
    case 'Discover':
      return <Discover aria-label="Discover" height={24} width={36} />;
    default:
      return <CreditCard />;
  }
};

interface FinancialAccountProps {
  userPaymentMethod: UserPaymentMethodRead;
  variant?: 'mui' | 'helix';
  disabled?: boolean;
}

export const FinancialAccount: React.FC<
  React.PropsWithChildren<FinancialAccountProps>
> = ({ userPaymentMethod, variant = 'mui', disabled, children }) => {
  const { data: appointmentReadinessIssues = { paymentMethod: [] } } =
    useAppointmentReadiness(userPaymentMethod.userId);

  const { type, isDefault } = userPaymentMethod;
  const brand = userPaymentMethod.card?.brand;
  const bankName = userPaymentMethod.bankAccount?.bankName;
  const name =
    type === UserPaymentMethodType.CARD
      ? brand || 'Credit card'
      : type === UserPaymentMethodType.BANK_ACCOUNT
        ? bankName || 'Bank account'
        : 'Account';
  const last4 =
    type === UserPaymentMethodType.CARD
      ? userPaymentMethod.card?.last4
      : userPaymentMethod.bankAccount?.last4;
  const isHsaOrFsaOrHra = !!userPaymentMethod.dateAttestedToFsaHsaHraFunding;
  const paymentMethodStatus = getPaymentMethodStatus({
    userPaymentMethod,
    issues: appointmentReadinessIssues.paymentMethod,
  });
  return (
    <div
      className={`${
        disabled ? 'bg-system-backgroundGray' : ''
      } border-system-borderGray flex items-center justify-between gap-x-2 rounded border border-solid px-5 py-3`}
    >
      <div className="flex items-center gap-2 overflow-hidden">
        <div className="flex h-6 items-center">
          {paymentMethodStatus === PaymentMethodStatus.VERIFIED ? (
            <IconCheckCircle
              color={
                variant === 'helix'
                  ? theme.color.system.green
                  : legacyTheme.color.primary
              }
            />
          ) : paymentMethodStatus === PaymentMethodStatus.UNVERIFIED ? (
            <IconWarningCircle color={theme.color.primary.yellow} />
          ) : (
            <IconWarning color={theme.color.primary.red} />
          )}
        </div>
        <div
          className={`flex items-center justify-center text-center ${
            variant === 'helix' ? 'text-black' : 'text-gray'
          }`}
        >
          {userPaymentMethod.type === UserPaymentMethodType.CARD ? (
            <CreditCardBrandIcon brand={brand} aria-hidden />
          ) : (
            <div className="border-system-borderGray flex h-6 w-9 items-center justify-center rounded border-[1px] border-solid px-2 py-[2px]">
              <AccountBalance
                fontSize="inherit"
                className="text-system-textBlack h-4 w-4"
                aria-hidden
              />
            </div>
          )}
        </div>
        <div className="flex w-full flex-1 flex-col flex-wrap justify-center gap-1 overflow-hidden text-start">
          <div
            className={`flex w-full items-center gap-x-2 ${
              variant === 'helix'
                ? 'hlx-typography-body'
                : 'font-sans text-base'
            } ${disabled ? 'text-system-gray' : ''}`}
          >
            <div className="flex w-full flex-wrap items-center gap-x-1">
              <span className="truncate">
                <ContentText variant="body/medium">{name}</ContentText>
              </span>{' '}
              <ContentText>ending in {last4}</ContentText>
              <div className="flex items-center gap-x-1">
                {isDefault && <Badge variant="neutral">Default</Badge>}
                <PaymentMethodStatusBadge
                  issues={appointmentReadinessIssues.paymentMethod}
                  userPaymentMethod={userPaymentMethod}
                />
              </div>
            </div>
          </div>
          {isHsaOrFsaOrHra && (
            <ContentText variant="body-small">HSA, FSA or HRA card</ContentText>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export const PaymentMethodStatusBadge = ({
  userPaymentMethod,
  issues = [],
}: {
  userPaymentMethod: UserPaymentMethodRead;
  issues?: UserAppointmentReadiness['paymentMethod'];
}) => {
  if (
    issues.some(
      ({ type }) =>
        type ===
        PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
    )
  ) {
    return <Badge variant="negative">Payment failed</Badge>;
  }

  if (issues.length > 0 && userPaymentMethod.isDefault) {
    switch (issues[0].type) {
      case PaymentMethodReadinessIssueMissingType.MISSING_PAYMENT_METHOD:
        return <Badge variant="negative">Payment method missing</Badge>;
      case PaymentMethodReadinessIssueCreditCardExpiredType.CREDIT_CARD_EXPIRED:
        return <Badge variant="negative">Expired</Badge>;
      case PaymentMethodReadinessIssueBankAccountUnverifiedType.BANK_ACCOUNT_UNVERIFIED:
        return <Badge variant="warning">Unverified</Badge>;
      case PaymentMethodReadinessIssuePreAuthChargeFailedType.PRE_AUTH_CHARGE_FAILED:
        return <Badge variant="negative">Verification failed</Badge>;
      default:
        return <Badge variant="negative">Verification failed</Badge>;
    }
  }

  if (userPaymentMethod.type === UserPaymentMethodType.BANK_ACCOUNT) {
    const verificationStatus =
      userPaymentMethod.bankAccount?.verificationStatus;

    switch (verificationStatus) {
      case BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION:
        return <Badge variant="warning">Pending automatic verification</Badge>;
      case BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION:
        return <Badge variant="warning">Pending manual verification</Badge>;
      case BankAccountVerificationStatus.VERIFICATION_FAILED:
        return <Badge variant="negative">Verification failed</Badge>;
      case BankAccountVerificationStatus.VERIFIED:
      default:
        return <Badge variant="positive">Verified</Badge>;
    }
  }

  if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
    if (userPaymentMethod.card?.isExpired) {
      return <Badge variant="negative">Expired</Badge>;
    }

    if (!userPaymentMethod.isVerified) {
      return <Badge variant="negative">Payment failed</Badge>;
    }
  }

  return <Badge variant="positive">Verified</Badge>;
};
