import imageCompression from 'browser-image-compression';
import moment from 'moment';
import * as Yup from 'yup';

import { ErrorCode } from '@headway/api/models/ErrorCode';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { InterpretInsuranceCardResponse } from '@headway/api/models/InterpretInsuranceCardResponse';
import { InvalidImageReason } from '@headway/api/models/InvalidImageReason';
import { LookupSource } from '@headway/api/models/LookupSource';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { EligibilityLookupApi } from '@headway/api/resources/EligibilityLookupApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { OCRQualityCheckCompletedEvent } from '@headway/avo';

import {
  CARRIERS_REQUIRING_AUTHORIZATION,
  DEFAULT_MEMBER_ID_PATTERN,
} from '../constants/carrierIds';
import {
  MAX_COMPRESSION_TIMEOUT_MS,
  MAX_IMAGE_COMPRESSION_SIZE_IN_MB,
} from '../constants/insuranceLookup';
import { UseQueryResult } from '../react-query';
import {
  InsuranceLookupFormError,
  InsuranceLookupFormPage,
} from '../types/insuranceCapture';
import {
  groupAndSortFrontEndCarriers,
  isCarrierInHeadwayNetworkForState,
} from './carriers';
import { canCarrierBeAcceptedInState } from './insuranceUtils';
import { logException } from './sentry';

export const SCANNABLE_FILE_TYPES = [
  'image/heic',
  'image/heif',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'application/pdf',
];

export const getFilteredLookupFormCarrierIdList = (
  carriers: Array<FrontEndCarrierRead>,
  carriersExclusionIds: Array<number>
): Array<FrontEndCarrierRead> => {
  const carriersExclusionSet = new Set(carriersExclusionIds);

  // Filter out "Other insurances" so that OON patients cannot proceed to booking (-1)
  // Filter out general Medicare so patient opts for state-specific Medicare carriers (8)
  // Filter out MVP healthcare https://therapymatch.slack.com/archives/C06UJSJJH2N/p1726601404369129
  return [...carriers].filter((c) => !carriersExclusionSet.has(c.id));
};

/**
 * Memoized function to convert carriers to options for the current state.
 * Only memoizes for one state at a time.
 */
export class MemoizedCarriersToOptionsForStateHelper {
  lastOptionsArgs:
    | [Array<FrontEndCarrierRead>, UnitedStates | undefined]
    | null;
  memoizedOptionsResult: Array<number> | null;
  constructor() {
    this.lastOptionsArgs = null;
    this.memoizedOptionsResult = null;
  }

  getCarrierIds(
    carriers: FrontEndCarrierRead[],
    state?: UnitedStates
  ): Array<number> {
    if (
      this.lastOptionsArgs &&
      this.lastOptionsArgs[0] === carriers &&
      this.lastOptionsArgs[1] === state &&
      this.memoizedOptionsResult
    ) {
      return this.memoizedOptionsResult;
    }

    let options = groupAndSortFrontEndCarriers(carriers, state).map(
      (carrier) => {
        return carrier.id;
      }
    );

    this.lastOptionsArgs = [carriers, state];
    this.memoizedOptionsResult = options;

    return options;
  }
}

export const enum CarrierGrouping {
  IN_HEADWAY_NETWORK_GROUPING = 'Insurance companies we work with',
  OUT_OF_HEADWAY_NETWORK_GROUPING = 'Other insurance companies',
}
/**
 * getCarrierGroupByFunction returns the string that categorizes the carrier for the options list thats being displayed in the carrier list
 * @param carriers
 * @param state
 * @returns
 */
export const getCarrierGroupByFunction = (
  carriers: Array<FrontEndCarrierRead>,
  state: UnitedStates
): ((carrierId: number) => CarrierGrouping) | undefined => {
  const carrierToGrouping: { [carrierId: number]: CarrierGrouping } = {};
  carriers.forEach((carrier) => {
    const isInNetwork = isCarrierInHeadwayNetworkForState(carrier, state);
    carrierToGrouping[carrier.id] = isInNetwork
      ? CarrierGrouping.IN_HEADWAY_NETWORK_GROUPING
      : CarrierGrouping.OUT_OF_HEADWAY_NETWORK_GROUPING;
  });

  const hasInNetworkCarriers = Object.values(carrierToGrouping).some(
    (grouping) => grouping === CarrierGrouping.IN_HEADWAY_NETWORK_GROUPING
  );
  // only apply grouped labels if there are in network carriers
  if (!hasInNetworkCarriers) {
    return undefined;
  }

  return (carrierId: number) => {
    return carrierToGrouping[carrierId];
  };
};

export async function getBlobFromString(blobUrl: string) {
  try {
    const blob = await fetch(blobUrl).then((response) => response.blob());
    return blob;
  } catch (error) {
    throw error;
  }
}

function getBlobSizeInMB(blob: Blob): number {
  return blob.size / (1024 * 1024);
}

export const maybeCompressImageWithTimeout = async (
  uncompressedImageUrl: string
): Promise<{ url: string; blob: Blob }> => {
  const uncompressedImageBlob = await getBlobFromString(uncompressedImageUrl);
  const uncompressedImageSize = getBlobSizeInMB(uncompressedImageBlob);
  const fallback = { url: uncompressedImageUrl, blob: uncompressedImageBlob };
  const helper = async (): Promise<{ url: string; blob: Blob }> => {
    try {
      if (uncompressedImageSize > MAX_IMAGE_COMPRESSION_SIZE_IN_MB) {
        const type = uncompressedImageBlob.type || 'image.jpg';
        const file = new File([uncompressedImageBlob], type, { type });
        const compressedBlob = await imageCompression(file, {
          maxSizeMB: MAX_IMAGE_COMPRESSION_SIZE_IN_MB,
        });
        return {
          url: URL.createObjectURL(compressedBlob),
          blob: compressedBlob,
        };
      }
    } catch (e) {
      logException(e);
    }
    return fallback;
  };

  const timeout: Promise<{ url: string; blob: Blob }> = new Promise((resolve) =>
    setTimeout(() => resolve(fallback), MAX_COMPRESSION_TIMEOUT_MS)
  );

  return Promise.race([helper(), timeout]);
};

export const enum InFormCarrierWarningStatus {
  CARRIER_NOT_IN_STATE = 'CARRIER_NOT_IN_STATE',
  PROVIDER_DOES_NOT_TAKE_PLAN = 'PROVIDER_DOES_NOT_TAKE_PLAN',
  CARRIERS_REQUIRING_AUTHORIZATION = 'CARRIERS_REQUIRING_INSURANCE',
}

/**
 *
 * this gets the status used in the lookup form to determine if there should be a warning to show, and what type of warning.
 * the selected fields are all fields in the form, while matchingProviderCarrierQueryResult is the result from react hook useMatchingProviderFrontEndCarrierQuery
 */
export const getInFormCarrierWarningStatus = ({
  selectedCarrier,
  selectedState,
  selectedMemberId,
  providerCarriers,
  matchingProviderCarrierQueryResult,
}: {
  selectedCarrier?: FrontEndCarrierRead;
  selectedState?: UnitedStates;
  selectedMemberId: string;
  providerCarriers: Array<ProviderFrontEndCarrierRead>;
  matchingProviderCarrierQueryResult: UseQueryResult<ProviderFrontEndCarrierRead>;
}): Array<InFormCarrierWarningStatus> => {
  if (!selectedCarrier) {
    return [];
  }

  const statuses: Array<InFormCarrierWarningStatus> = [];
  if (!canCarrierBeAcceptedInState(selectedCarrier, selectedState)) {
    statuses.push(InFormCarrierWarningStatus.CARRIER_NOT_IN_STATE);
  }

  const {
    isLoading: isLoadingMatchingProviderFrontEndCarrier,
    data: matchingProviderFrontEndCarrier,
  } = matchingProviderCarrierQueryResult;
  if (
    providerCarriers.length > 0 &&
    // bcbs blue card member ids start with 3 letters.
    // we should wait until we know whether it's blue card
    // to show the error message
    selectedMemberId &&
    selectedMemberId.length >= 3 &&
    !isLoadingMatchingProviderFrontEndCarrier &&
    !matchingProviderFrontEndCarrier
  ) {
    statuses.push(InFormCarrierWarningStatus.PROVIDER_DOES_NOT_TAKE_PLAN);
  }

  if (CARRIERS_REQUIRING_AUTHORIZATION.includes(selectedCarrier.id)) {
    statuses.push(InFormCarrierWarningStatus.CARRIERS_REQUIRING_AUTHORIZATION);
  }

  return statuses;
};

const isClaimUnreadySecondaryPlan = (user: UserRead) => {
  return (
    user.activeUserInsurance?.latestEligibilityLookup?.isSecondary &&
    !user.activeUserInsurance?.latestEligibilityLookup?.isClaimReady
  );
};

export type PerformLookupArgs = {
  updateFields: {
    firstName: string;
    lastName: string;
    dob: string;
    memberId: string;
    groupNumber?: string;

    carrier?: FrontEndCarrierRead;
    phone?: string;
    email?: string;

    lastSearchedState: UnitedStates;
    insuranceCardInterpretationResultId?: number;
  };

  shouldCheckEligibility: boolean;
  isPatientOnboarding?: boolean;
  carriers: Array<FrontEndCarrierRead>;
  existingUser: UserRead;
  setUpdatedFrontEndUser: (user: UserRead) => void;
  startedCheckingEligibility: () => void;
};

export type PerformLookupErrors = {
  claimUnreadySecondaryPlan?: boolean;
  // straight from refreshedEligiblityLookup
  lookupErrorCodes?: Array<ErrorCode>;
  // straight from refreshedEligiblityLookup
  lookupOtherErrors?: Array<string>;
  // this could mean the lookup was successful or failed, but its an error for the form to handle
  lookupFormErrors: Array<InsuranceLookupFormError>;
};

export const performLookup = async (
  args: PerformLookupArgs
): Promise<{
  errorStates?: PerformLookupErrors;
  insurance?: UserInsuranceRead;
}> => {
  // Currently this is only used on the patient OCR flow and not the provider OCR flow.
  // This is because the provider OCR flow is handled by the AddBillingInformationWorkflow component
  const {
    updateFields,
    shouldCheckEligibility,
    existingUser,
    startedCheckingEligibility,
    setUpdatedFrontEndUser,
    carriers,
  } = args;
  let user = existingUser;
  const errorStates: PerformLookupErrors = { lookupFormErrors: [] };
  try {
    let insurance = user.activeUserInsurance;
    if (updateFields.carrier) {
      const dobMoment = moment(updateFields.dob).format('YYYY-MM-DD');
      insurance = await UserInsuranceApi.createUserInsurance({
        userInsuranceIn: {
          userId: user.id,
          firstName: updateFields.firstName,
          lastName: updateFields.lastName,
          dob: dobMoment,
          memberId: updateFields.memberId,
          groupNumber: updateFields.groupNumber,
          frontEndCarrierName: updateFields.carrier.name,
          frontEndCarrierId: updateFields.carrier.id,
          insuranceCardInterpretationResultId:
            updateFields.insuranceCardInterpretationResultId,
        },
        lastSearchedState: updateFields.lastSearchedState,
        phoneNumber: updateFields.phone,
        email: updateFields.email,
      });
    }

    if (!insurance) {
      throw new Error(
        `Unexpected missing insurance when submitting performing lookup for insurance form`
      );
    }

    user = await UserApi.getUserMe();
    setUpdatedFrontEndUser(user);

    /******************************************************************/
    /*                            NOTE!!!!                            */
    /* Any changes to the UserInsurance should include a              */
    /* corresponding update to the `AuthStore.user` so that the UI    */
    /* can update accordingly!!!!!                                    */
    /******************************************************************/
    if (shouldCheckEligibility) {
      startedCheckingEligibility();
      try {
        const refreshedEligibilityLookup =
          await EligibilityLookupApi.refreshUserEligibilityLookup(
            user.id,
            insurance.id,
            { lookup_source: LookupSource.PATIENT_INSURANCE_UPLOAD }
          );
        user = await UserApi.getUserMe();
        setUpdatedFrontEndUser(user);

        if (insurance.id) {
          // outage is created for user insurance in post_lookup hook, so refresh insurance here
          insurance = await UserInsuranceApi.getUserInsurance(insurance.id);

          if (insurance.isInOutage) {
            errorStates.lookupFormErrors.push(
              InsuranceLookupFormError.VERIFICATION_IN_PROGRESS
            );
            return { errorStates, insurance };
          }
        }

        if (refreshedEligibilityLookup?.outOfNetwork) {
          errorStates.lookupFormErrors.push(
            InsuranceLookupFormError.OUT_OF_NETWORK
          );
          return { errorStates, insurance };
        }

        errorStates.lookupErrorCodes = refreshedEligibilityLookup?.errorCodes
          ?.length
          ? refreshedEligibilityLookup.errorCodes
          : undefined;
        errorStates.lookupOtherErrors = refreshedEligibilityLookup?.otherErrors
          ?.length
          ? refreshedEligibilityLookup.otherErrors
          : undefined;
        // we are purposefully not returning here because different flows handle these errors differently
        if (
          refreshedEligibilityLookup?.isFuzzyMatched ||
          insurance.wasFrontEndCarrierCorrected
        ) {
          errorStates.lookupFormErrors.push(
            InsuranceLookupFormError.VERIFY_FUZZY_MATCH
          );
        }

        if (
          refreshedEligibilityLookup?.lookupStatus ===
          LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION
        ) {
          errorStates.lookupFormErrors.push(
            InsuranceLookupFormError.LOOKUP_FAILED
          );
          return { errorStates, insurance };
        }

        user = await UserApi.getUserMe();
      } catch (error) {
        logException(error);
      }
    }

    setUpdatedFrontEndUser(user);

    if (isClaimUnreadySecondaryPlan(user)) {
      errorStates.claimUnreadySecondaryPlan = true;
      return { errorStates, insurance };
    }

    return {
      insurance,
      errorStates: Object.values(errorStates).some(
        (value) =>
          value === true || (value instanceof Array && value.length > 0)
      )
        ? errorStates
        : undefined,
    };
  } catch (error) {
    logException(error);
    user = await UserApi.getUserMe();
    setUpdatedFrontEndUser(user);

    if (isClaimUnreadySecondaryPlan(user)) {
      errorStates.claimUnreadySecondaryPlan = true;
      return { errorStates, insurance: user.activeUserInsurance };
    }

    const carrier = carriers.find(
      ({ id }) => user.activeUserInsurance?.frontEndCarrierId === id
    );

    const isOon =
      carrier &&
      user.lastSearchedState &&
      !canCarrierBeAcceptedInState(carrier, user.lastSearchedState);

    errorStates.lookupFormErrors.push(
      isOon
        ? InsuranceLookupFormError.OUT_OF_NETWORK
        : InsuranceLookupFormError.LOOKUP_FAILED
    );
    return { errorStates, insurance: user.activeUserInsurance };
  }
};

export const isInterpretationResponseUnreadable = (
  res: InterpretInsuranceCardResponse
) => {
  /*
    return true if first name, last name, carrier, and member id are all falsy,
    else return false.

    this scenario indicates the user likely sent us photos of something other
    than an insurance card.

    we use this function to decide whether to show the user a message indicating
    the above during the OCR flow.
  */
  const card = res.insuranceCardInfo;
  return (
    !card?.memberFirstName &&
    !card?.memberLastName &&
    !card?.behavioralHealthCarrier && // TODO[am]: I think this should be insuranceCarrier
    !card?.memberId
  );
};

export const fileTypePermitted = (file: File) => {
  return SCANNABLE_FILE_TYPES.includes(file.type);
};

export type AnalyticsOCRQualityCheckFailureReason =
  OCRQualityCheckCompletedEvent['properties']['qualityCheckFailureReason'];
// const hello: OCRQualityCheckCompletedEvent = {} as OCRQualityCheckCompletedEvent
// hello.properties.qualityCheckFailureReason
export const getAnalyticsOCRFailureReason = (
  invalidReason: InvalidImageReason
): AnalyticsOCRQualityCheckFailureReason => {
  const reasonsMap: {
    [r in InvalidImageReason]: AnalyticsOCRQualityCheckFailureReason;
  } = {
    [InvalidImageReason.BLURRY]: 'blurry',
    [InvalidImageReason.LOW_RESOLUTION]: 'low_resolution',
    [InvalidImageReason.UNSUPPORTED_FORMAT]: 'unsupported_format',
    [InvalidImageReason.UNKNOWN]: 'unknown',
    [InvalidImageReason.FILE_TOO_LARGE]: 'file_size_too_large',
    [InvalidImageReason.MAXIMUM_SIZE_EXCEEDED]: 'file_size_too_large',
    [InvalidImageReason.UNKNOWN_FORMAT]: 'unsupported_format',
    [InvalidImageReason.INVALID_FILENAME]: 'unsupported_format',
    [InvalidImageReason.UNPARSEABLE_MODEL_RESPONSE]:
      'unparseable_model_response',
  };
  return reasonsMap[invalidReason];
};

// we have a request size limit for mamba and ningx but that is for two images
export const CLIENT_SIDE_FILE_SIZE_LIMIT = 10 * 1024 * 1024;

export const hasUploadTooLargeMessage = (error: unknown): boolean => {
  return Boolean(
    error &&
      typeof error === 'object' &&
      'message' in error &&
      typeof error.message === 'string' &&
      error.message === 'Request failed with status code 413'
  );
};

export const getInsuranceLookupFormPageFromPageUrl =
  (): InsuranceLookupFormPage => {
    const pathname =
      typeof window !== 'undefined' ? window.location.pathname : '';
    // /benefits/lookup
    if (pathname.startsWith('/benefits')) {
      return InsuranceLookupFormPage.BENEFITS_HUB;
    }
    if (pathname === '/account') {
      return InsuranceLookupFormPage.ACCOUNT;
    }
    // /providers/lincoln-stark/book?type=APPOINTMENT
    if (pathname.startsWith('/providers') && pathname.includes('/book')) {
      return InsuranceLookupFormPage.CHECKOUT;
    }
    // /providers/lincoln-stark
    if (pathname.startsWith('/providers')) {
      return InsuranceLookupFormPage.PROVIDER_PROFILE;
    }
    // /onboard?token=ey.....
    if (pathname.startsWith('/onboard')) {
      return InsuranceLookupFormPage.REFERRED_PATIENT_ONBOARDING;
    }
    if (pathname.startsWith('/referral')) {
      return InsuranceLookupFormPage.HEALTHCARE_REFERRAL;
    }
    if (pathname === '/contact') {
      return InsuranceLookupFormPage.CONTACT_FORM;
    }
    // /clients/1747/billing
    if (pathname.startsWith('/clients') && pathname.includes('/billing')) {
      return InsuranceLookupFormPage.SIGMUND_CLIENT_PAGE;
    }
    if (pathname === '/clients') {
      return InsuranceLookupFormPage.SIGMUND_ADD_CLIENT_FLOW;
    }
    if (pathname === '/calendar') {
      return InsuranceLookupFormPage.SIGMUND_CALENDAR;
    }
    return InsuranceLookupFormPage.BENEFITS_HUB;
  };

/**
 * Uses the current page URL to determine the context of the insurance lookup form and converts that to avo context
 */
export const getAvoInsuranceFormContext = () => {
  const insuranceLookupFormPage = getInsuranceLookupFormPageFromPageUrl();
  const contextMap: {
    [k in InsuranceLookupFormPage]:
      | 'provider_profile'
      | 'checkout'
      | 'account'
      | 'benefits_hub'
      | 'referred_patient_onboarding'
      | 'healthcare_referral'
      | 'contact_form'
      | 'sigmund_add_client_flow'
      | 'sigmund_client_page'
      | 'sigmund_calendar';
  } = {
    [InsuranceLookupFormPage.ACCOUNT]: 'account',
    [InsuranceLookupFormPage.CHECKOUT]: 'checkout',
    [InsuranceLookupFormPage.PROVIDER_PROFILE]: 'provider_profile',
    [InsuranceLookupFormPage.BENEFITS_HUB]: 'benefits_hub',
    [InsuranceLookupFormPage.REFERRED_PATIENT_ONBOARDING]:
      'referred_patient_onboarding',
    [InsuranceLookupFormPage.HEALTHCARE_REFERRAL]: 'healthcare_referral',
    [InsuranceLookupFormPage.CONTACT_FORM]: 'contact_form',
    [InsuranceLookupFormPage.SIGMUND_ADD_CLIENT_FLOW]:
      'sigmund_add_client_flow',
    [InsuranceLookupFormPage.SIGMUND_CLIENT_PAGE]: 'sigmund_client_page',
    [InsuranceLookupFormPage.SIGMUND_CALENDAR]: 'sigmund_calendar',
  };
  return contextMap[insuranceLookupFormPage];
};

const minimumDob =
  process.env.NODE_ENV === ('development' || 'test')
    ? '1880-01-01'
    : '1900-01-01';

export const LOOKUP_VALIDATION = Yup.object().shape({
  basicFirstName: Yup.string()
    .required('First name is required.')
    .test(
      'first name',
      'Please enter a first name',
      (value) => !!(value && value.trim())
    ),
  basicLastName: Yup.string()
    .required('Last name is required.')
    .test(
      'last name',
      'Please enter a last name',
      (value) => !!(value && value.trim())
    ),
  dob: Yup.string()
    .required('Date of birth is required.')
    .test('valid date of birth', 'Please enter valid date of birth', (value) =>
      moment(value).isBetween(minimumDob, moment())
    )
    .nullable(),
  carrierId: Yup.number().required('Insurance company is required.'),
  memberId: Yup.string()
    .required('Insurance member ID is required.')
    .matches(
      DEFAULT_MEMBER_ID_PATTERN,
      'Make sure this matches the front of your insurance card and only includes letters and numbers.'
    ),
  groupNumber: Yup.string().notRequired(),
  state: Yup.mixed<UnitedStates>()
    .oneOf(Object.values(UnitedStates))
    .required('Location is required'),
});
