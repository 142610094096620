import { useViewportSize } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';
import { DismissButton, Overlay, useModalOverlay } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';
import { Transition, TransitionStatus } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';

interface TrayProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
  height?: 'vh' | 'fit-content';
  __className?: string;
}

export function Tray({
  children,
  state,
  height,
  __className,
  ...props
}: TrayProps) {
  let wrapperRef = React.useRef<HTMLDivElement>(null);

  let [exited, setExited] = React.useState(!state.isOpen);

  let handleEntered = React.useCallback(() => {
    setExited(false);
  }, []);

  let handleExited = React.useCallback(() => {
    setExited(true);
  }, []);

  // mount the overlay if it's open or if it's closed but still animating closed
  const mountOverlay = state.isOpen || (!state.isOpen && !exited);
  if (!mountOverlay) {
    return null;
  }

  return (
    <Overlay {...props}>
      <OpenTransition
        in={state.isOpen}
        appear
        onExited={handleExited}
        onEntered={handleEntered}
        nodeRef={wrapperRef}
        addEndListener={() => {
          // noop
        }}
      >
        <TrayPanel
          state={state}
          className={__className}
          wrapperRef={wrapperRef}
          // this is just to appease the type checker. This prop gets overwritten by the Transition component
          isOpen={state.isOpen}
          height={height}
        >
          {children}
        </TrayPanel>
      </OpenTransition>
    </Overlay>
  );
}

interface TrayPanelProps {
  children: React.ReactNode;
  state: OverlayTriggerState;
  wrapperRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  height?: 'vh' | 'fit-content';
  className?: string;
}

function TrayPanel({
  className,
  children,
  wrapperRef,
  isOpen,
  state,
  height = 'vh',
  ...props
}: TrayPanelProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  let { modalProps, underlayProps } = useModalOverlay(
    {
      ...props,
      isDismissable: true,
    },
    state,
    ref
  );

  let viewport = useViewportSize();

  return (
    <div ref={wrapperRef}>
      <div {...underlayProps} className="hlx-tray-underlay" />
      <div
        className={clsx('hlx-tray-root', className)}
        style={{
          // @ts-ignore
          '--height': height === 'vh' ? `${viewport.height}px` : 'auto',
        }}
      >
        <div
          {...modalProps}
          className="hlx-tray-panel"
          data-open={isOpen}
          ref={ref}
        >
          <DismissButton onDismiss={state.close} />
          {children}
          <DismissButton onDismiss={state.close} />
        </div>
      </div>
    </div>
  );
}

const OPEN_STATES: Partial<Record<TransitionStatus, boolean>> = {
  entering: false,
  entered: true,
};

type OpenTransitionProps = TransitionProps<HTMLDivElement> & {
  children: React.ReactNode;
};

export function OpenTransition(props: OpenTransitionProps) {
  return (
    <Transition timeout={{ enter: 0, exit: 350 }} {...props}>
      {(state) =>
        React.Children.map(props.children, (child) => {
          return (
            child &&
            React.cloneElement(child as React.ReactElement, {
              isOpen: !!OPEN_STATES[state],
            })
          );
        })
      }
    </Transition>
  );
}
