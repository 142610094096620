import { inject, observer } from 'mobx-react';
import React from 'react';

import { LoginRequest } from '@headway/api/models/LoginRequest';
import { SignupRequest } from '@headway/api/models/SignupRequest';
import { UserRead } from '@headway/api/models/UserRead';

export interface IAuthStore {
  closeForgotPasswordModal: () => void;
  closeLoginModal: () => void;
  closeResetPasswordModal: () => void;
  closeSignupModal: () => void;
  forgotPasswordModalOpen: boolean;
  gatedRedirectLocation?: string;
  inviteToken?: string;
  isUserRegistered: () => boolean;
  logIn: (loginRequest: LoginRequest) => Promise<UserRead>;
  loginModalOpen: boolean;
  logout: ({ postLogOutRedirectTo }: { postLogOutRedirectTo?: string }) => void;
  openForgotPasswordModal: () => void;
  openLoginModal: () => void;
  openResetPasswordModal: () => void;
  openSignupModal: () => void;
  redirectToAuth0Login: (postLogInRedirectTo?: string) => void;
  resetPasswordModalOpen: boolean;
  resetPasswordToken?: string;
  setUser: (user: UserRead) => void;
  signUp: (signupRequest: SignupRequest) => Promise<UserRead>;
  signupModalOpen: boolean;
  user: UserRead;
}

export interface WithStoresChildProps {
  AuthStore: IAuthStore;
}

export function withStores<
  T extends WithStoresChildProps = WithStoresChildProps,
>(Component: React.ComponentType<T>) {
  const WithStores = inject('AuthStore')(
    observer(
      class WithStores extends React.Component<
        Omit<T, keyof WithStoresChildProps>
      > {
        render() {
          const { AuthStore, ...rest } = this.props as T;
          if (AuthStore) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { ..._unusedMobXForceRerenderAuthStore } = AuthStore; // have to do this to force mobx to re-render this component

            return <Component {...(rest as T)} AuthStore={AuthStore} />;
          }

          return null;
        }
      }
    )
  );

  return WithStores;
}
