/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum Ethnicity {
    BLACK_OR_AFRICAN_DESCENT = 'black_or_african_descent',
    CAUCASIAN = 'caucasian',
    EAST_ASIAN = 'east_asian',
    HISPANIC = 'hispanic',
    LATINX = 'latinx',
    NATIVE_AMERICAN = 'native_american',
    OTHER_RACIAL_OR_ETHNIC_BACKGROUND = 'other_racial_or_ethnic_background',
    PACIFIC_ISLANDER = 'pacific_islander',
    SOUTH_ASIAN = 'south_asian',
    SOUTHEAST_ASIAN = 'southeast_asian'
}


export const EthnicityMetadata: EnumMetadata<Ethnicity> = {
    name: 'Ethnicity',
    values: {
            BLACK_OR_AFRICAN_DESCENT: 'black_or_african_descent',
            CAUCASIAN: 'caucasian',
            EAST_ASIAN: 'east_asian',
            HISPANIC: 'hispanic',
            LATINX: 'latinx',
            NATIVE_AMERICAN: 'native_american',
            OTHER_RACIAL_OR_ETHNIC_BACKGROUND: 'other_racial_or_ethnic_background',
            PACIFIC_ISLANDER: 'pacific_islander',
            SOUTH_ASIAN: 'south_asian',
            SOUTHEAST_ASIAN: 'southeast_asian'
    }
}
