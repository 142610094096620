import { useFormValidationState } from '@react-stately/form';
import { AriaSwitchProps } from '@react-types/switch';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { mergeProps, useSwitch, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import { Simplify } from 'type-fest';

import { DATA } from './consts';
import { FieldRoot } from './fields';
import { Validator } from './forms';
import { useFieldValidity } from './useFieldValidity';
import { FormInputProps, useFormInput } from './useFormInput';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type SwitchProps = Simplify<
  {
    labelPosition?: 'left' | 'right';
    constraints?: Validator<boolean>[];
  } & Omit<FormInputProps<string, boolean>, 'label'> &
    PickAndConfigure<
      AriaSwitchProps,
      | { children: 'children' }
      | { selected?: 'isSelected' }
      | { defaultSelected?: 'defaultSelected' }
      | { 'aria-describedby'?: 'aria-describedby' }
    >
>;

export const Switch = ({
  labelPosition = 'left',
  selected,
  defaultSelected,
  ...props
}: SwitchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const { ariaProps, hoverProps, focusProps, isHovered, isFocusVisible } =
    useFormInput({ isSelected: selected, ...props });

  const toggleState = useToggleState({ ...ariaProps, defaultSelected });

  const { errorMessage, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (validation.isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      inputRef.current?.focus();
    },
  });

  const { inputProps } = useSwitch(
    { ...ariaProps, ...validationProps, defaultSelected },
    toggleState,
    inputRef
  );

  const { displayValidation: validation } = useFormValidationState<boolean>({
    ...validationProps,
    value: toggleState.isSelected,
  });

  const Icon = toggleState.isSelected ? SwitchOnIcon : SwitchOffIcon;

  return (
    <FieldRoot
      ref={rootRef}
      className="hlx-switch-root"
      style={{
        flexDirection: labelPosition === 'left' ? 'row' : 'row-reverse',
      }}
      isDisabled={props.disabled}
      isReadonly={props.readonly}
      isInvalid={validation.isInvalid}
      {...mergeProps(hoverProps, {
        [DATA.HOVERED]: !ariaProps.isReadOnly && isHovered,
        'data-hlx-selected': toggleState.isSelected,
      })}
    >
      <label>
        <VisuallyHidden>
          <input ref={inputRef} {...inputProps} {...focusProps} />
        </VisuallyHidden>
        {props.children}
        <Icon
          className={clsx('hlx-switch-icon', {
            'focus-ring': isFocusVisible,
          })}
          aria-hidden="true"
        />
      </label>
    </FieldRoot>
  );
};

const SwitchOnIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="20"
      fill="none"
      {...props}
    >
      <rect width="36" height="20" className="hlx-switch-track" rx="10" />
      <circle cx="26" cy="10" r="6" className="hlx-switch-handle" />
    </svg>
  );
};

const SwitchOffIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="20"
      fill="none"
      {...props}
    >
      <rect
        className="hlx-switch-track"
        width="35"
        height="19"
        x="0.5"
        y="0.5"
        stroke="transparent"
        rx="9.5"
      />
      <circle cx="10" cy="10" r="6" className="hlx-switch-handle" />
    </svg>
  );
};
