/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UserPrescriberPreference {
    PRESCRIBER_ONLY = 'PRESCRIBER_ONLY',
    PRESCRIBER_AND_NON_PRESCRIBER = 'PRESCRIBER_AND_NON_PRESCRIBER',
    NON_PRESCRIBER_ONLY = 'NON_PRESCRIBER_ONLY',
    NOT_SURE = 'NOT_SURE',
    NO_PREFERENCE = 'NO_PREFERENCE'
}


export const UserPrescriberPreferenceMetadata: EnumMetadata<UserPrescriberPreference> = {
    name: 'UserPrescriberPreference',
    values: {
            PRESCRIBER_ONLY: 'PRESCRIBER_ONLY',
            PRESCRIBER_AND_NON_PRESCRIBER: 'PRESCRIBER_AND_NON_PRESCRIBER',
            NON_PRESCRIBER_ONLY: 'NON_PRESCRIBER_ONLY',
            NOT_SURE: 'NOT_SURE',
            NO_PREFERENCE: 'NO_PREFERENCE'
    }
}
