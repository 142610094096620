import { useResizeObserver } from '@react-aria/utils';
import { useViewportSize } from '@react-aria/utils';
import React from 'react';
import {
  mergeProps,
  useFocusVisible,
  useId,
  useIsSSR,
  VisuallyHidden,
} from 'react-aria';

import { theme } from './theme';

export {
  useId,
  useResizeObserver,
  mergeProps,
  useViewportSize,
  VisuallyHidden,
  useFocusVisible,
};

export function useIsMobileDevice(): boolean {
  let isSSR = useIsSSR();

  if (isSSR || typeof window === 'undefined') {
    return false;
  }

  return window.screen.width < theme.breakpoints.tablet;
}

export function useMediaQuery(query: string) {
  const supportsMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia === 'function';

  const normalizedQuery = query.replace(/@media\s/, '');

  const [matches, setMatches] = React.useState(
    () => supportsMatchMedia && window.matchMedia(normalizedQuery).matches
  );

  React.useEffect(() => {
    if (!supportsMatchMedia) {
      return;
    }

    const mq = window.matchMedia(normalizedQuery);

    const onChange = (evt: MediaQueryListEvent) => {
      setMatches(evt.matches);
    };

    /**
     * Older versions of Safari don't support addEventListener on MediaQueryList
     * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
     */
    if (!('addEventListener' in mq)) {
      //@ts-ignore
      mq.addListener(onChange);
      return () => {
        //@ts-ignore
        mq.removeListener(onChange);
      };
    } else {
      mq.addEventListener('change', onChange);
      return () => {
        mq.removeEventListener('change', onChange);
      };
    }
  }, [supportsMatchMedia, normalizedQuery]);

  // If in SSR, the media query should never match. Once the page hydrates,
  // this will update and the real value will be returned.
  const isSSR = useIsSSR();
  return isSSR ? false : matches;
}

export function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
