/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum PatientInsuranceOrEAPStatus {
    NO_INSURANCE = 'NO_INSURANCE',
    OUT_OF_NETWORK = 'OUT_OF_NETWORK',
    IN_NETWORK = 'IN_NETWORK',
    NO_DATA_OUTAGE = 'NO_DATA_OUTAGE',
    OLD_DATA_OUTAGE = 'OLD_DATA_OUTAGE',
    COB_FREEZE = 'COB_FREEZE',
    COB_FREEZE_AWAITING_USER = 'COB_FREEZE_AWAITING_USER',
    OTHER_FREEZE = 'OTHER_FREEZE',
    OON_FREEZE = 'OON_FREEZE',
    TERMED_PLAN_FREEZE = 'TERMED_PLAN_FREEZE',
    WAIVED_SESSION_MAX_HIT_FREEZE = 'WAIVED_SESSION_MAX_HIT_FREEZE',
    MATCHING_INCOMPLETE = 'MATCHING_INCOMPLETE',
    MATCHING_PENDING_VERIFICATION = 'MATCHING_PENDING_VERIFICATION',
    MATCHING_FAILED_VERIFICATION = 'MATCHING_FAILED_VERIFICATION',
    SECONDARY_INSURANCE = 'SECONDARY_INSURANCE',
    NO_REMAINING_COVERED_SESSIONS = 'NO_REMAINING_COVERED_SESSIONS',
    IN_NETWORK_PENDING_CREDENTIALING = 'IN_NETWORK_PENDING_CREDENTIALING',
    VIRTUAL_ONLY_NETWORK = 'VIRTUAL_ONLY_NETWORK',
    OUT_OF_NETWORK_NOT_LICENSED = 'OUT_OF_NETWORK_NOT_LICENSED',
    OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE = 'OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE',
    OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE = 'OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE'
}


export const PatientInsuranceOrEAPStatusMetadata: EnumMetadata<PatientInsuranceOrEAPStatus> = {
    name: 'PatientInsuranceOrEAPStatus',
    values: {
            NO_INSURANCE: 'NO_INSURANCE',
            OUT_OF_NETWORK: 'OUT_OF_NETWORK',
            IN_NETWORK: 'IN_NETWORK',
            NO_DATA_OUTAGE: 'NO_DATA_OUTAGE',
            OLD_DATA_OUTAGE: 'OLD_DATA_OUTAGE',
            COB_FREEZE: 'COB_FREEZE',
            COB_FREEZE_AWAITING_USER: 'COB_FREEZE_AWAITING_USER',
            OTHER_FREEZE: 'OTHER_FREEZE',
            OON_FREEZE: 'OON_FREEZE',
            TERMED_PLAN_FREEZE: 'TERMED_PLAN_FREEZE',
            WAIVED_SESSION_MAX_HIT_FREEZE: 'WAIVED_SESSION_MAX_HIT_FREEZE',
            MATCHING_INCOMPLETE: 'MATCHING_INCOMPLETE',
            MATCHING_PENDING_VERIFICATION: 'MATCHING_PENDING_VERIFICATION',
            MATCHING_FAILED_VERIFICATION: 'MATCHING_FAILED_VERIFICATION',
            SECONDARY_INSURANCE: 'SECONDARY_INSURANCE',
            NO_REMAINING_COVERED_SESSIONS: 'NO_REMAINING_COVERED_SESSIONS',
            IN_NETWORK_PENDING_CREDENTIALING: 'IN_NETWORK_PENDING_CREDENTIALING',
            VIRTUAL_ONLY_NETWORK: 'VIRTUAL_ONLY_NETWORK',
            OUT_OF_NETWORK_NOT_LICENSED: 'OUT_OF_NETWORK_NOT_LICENSED',
            OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE: 'OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE',
            OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE: 'OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE'
    }
}
