/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum FrontEndCarrierIdentifier {
    NONE_OF_THESE = -1,
    AETNA = 1,
    CIGNA = 3,
    EMBLEM = 4,
    EMPIRE_BLUE_CROSS_BLUE_SHIELD = 5,
    HARVARD_PILGRIM_HEALTHCARE = 6,
    MEDICAID = 7,
    MEDICARE = 8,
    MVP_HEALTHCARE = 9,
    OXFORD = 10,
    UNITED_HEALTHCARE = 11,
    KAISER_PERMANENTE_OF_COLORADO = 16,
    KAISER_PERMANENTE_OF_GEORGIA = 17,
    KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA = 18,
    KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA = 19,
    KAISER_PERMANENTE_OF_MID_ATLANTIC = 20,
    KAISER_PERMANENTE_OF_NORTHWEST = 21,
    HUMANA = 22,
    OSCAR_HEALTH = 23,
    ANTHEM_BLUE_CROSS_CALIFORNIA = 24,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_COLORADO = 25,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_CONNECTICUT = 26,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_INDIANA = 27,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_KENTUCKY = 28,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_MAINE = 29,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_MISSOURI = 30,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_NEVADA = 31,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_NEW_HAMPSHIRE = 32,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_OHIO = 33,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_VIRGINIA = 34,
    ANTHEM_BLUE_CROSS_BLUE_SHIELD_WISCONSIN = 35,
    BLUE_CROSS_BLUE_SHIELD_ARKANSAS = 36,
    BLUE_CROSS_BLUE_SHIELD_MISSISSIPPI = 37,
    BLUE_CROSS_BLUE_SHIELD_ARIZONA = 38,
    BLUE_CROSS_BLUE_SHIELD_GEORGIA = 39,
    BLUE_CROSS_BLUE_SHIELD_MICHIGAN = 40,
    BLUE_CROSS_BLUE_SHIELD_NORTH_DAKOTA = 41,
    BLUE_CROSS_BLUE_SHIELD_WYOMING = 42,
    BLUE_CROSS_BLUE_SHIELD_ALABAMA = 43,
    BLUE_CROSS_BLUE_SHIELD_HAWAII = 44,
    BLUE_CROSS_BLUE_SHIELD_ILLINOIS = 45,
    BLUE_CROSS_BLUE_SHIELD_KANSAS = 46,
    BLUE_CROSS_BLUE_SHIELD_KANSAS_CITY = 47,
    BLUE_CROSS_BLUE_SHIELD_LOUISIANA = 48,
    BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS = 49,
    BLUE_CROSS_BLUE_SHIELD_MINNESOTA = 50,
    BLUE_CROSS_BLUE_SHIELD_MONTANA = 51,
    BLUE_CROSS_BLUE_SHIELD_NEBRASKA = 52,
    BLUE_CROSS_BLUE_SHIELD_NEW_MEXICO = 53,
    BLUE_CROSS_BLUE_SHIELD_NORTH_CAROLINA = 54,
    BLUE_CROSS_BLUE_SHIELD_OKLAHOMA = 55,
    BLUE_CROSS_BLUE_SHIELD_RHODE_ISLAND = 56,
    BLUE_CROSS_BLUE_SHIELD_SOUTH_CAROLINA = 57,
    BLUE_CROSS_BLUE_SHIELD_TEXAS = 58,
    BLUE_CROSS_BLUE_SHIELD_VERMONT = 59,
    BLUE_CROSS_IDAHO = 60,
    BLUE_SHIELD_CALIFORNIA = 61,
    BLUE_CROSS_BLUE_SHIELD_PUERTO_RICO = 62,
    BLUE_CROSS_BLUE_SHIELD_TENNESSEE = 63,
    BLUE_CROSS_BLUE_SHIELD_WESTERN_NEW_YORK = 64,
    BLUE_SHIELD_NORTHEASTERN_NEW_YORK = 65,
    CAPITAL_BLUE_CROSS_PENNSYLVANIA = 66,
    CARE_FIRST_BLUE_CROSS_BLUE_SHIELD = 67,
    CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_DC = 162,
    CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_MARYLAND = 68,
    EXCELLUS_BLUE_CROSS_BLUE_SHIELD_NEW_YORK = 69,
    FLORIDA_BLUE = 70,
    HIGHMARK_BLUE_CROSS_BLUE_SHIELD_DELAWARE = 71,
    HIGHMARK_BLUE_CROSS_BLUE_SHIELD_PENNSYLVANIA = 72,
    HIGHMARK_BLUE_CROSS_BLUE_SHIELD_WEST_VIRGINIA = 73,
    HIGHMARK_BLUE_SHIELD_PENNSYLVANIA = 74,
    HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY = 75,
    INDEPENDENCE_BLUE_CROSS_PENNSYLVANIA = 76,
    PREMERA_BLUE_CROSS_WASHINGTON = 77,
    PREMERA_BLUE_CROSS_BLUE_SHIELD_ALASKA = 78,
    REGENCE_BLUE_CROSS_BLUE_SHIELD_OREGON = 79,
    REGENCE_BLUE_CROSS_BLUE_SHIELD_UTAH = 80,
    REGENCE_BLUE_SHIELD_WASHINGTON = 81,
    REGENCE_BLUE_SHIELD_IDAHO = 82,
    WELLMARK_BLUE_CROSS_BLUE_SHIELD = 83,
    GEHA_AETNA = 84,
    CSFMH = 85,
    MERITN = 86,
    ALBENS = 87,
    TRSMRK = 88,
    STDNT = 89,
    UMRWAU = 90,
    AMMEDS = 91,
    TUFTS = 92,
    WELLFLEET_CIGNA = 93,
    PRIORITY_HEALTH = 99,
    CARE_FIRST_VIRGINIA = 100,
    AVMED = 104,
    BIND = 106,
    HEALTH_PARTNERS = 107,
    GOLDEN_RULE = 108,
    BLUE_CROSS_WA_AK_PREMERA_BLUE_CROSS = 112,
    AETNA_BETTER_HEALTH_OF_FLORIDA = 113,
    MAGELLAN = 114,
    BEACON_HEALTH = 115,
    WEBTPA = 116,
    HEALTH_PLANS_INC_UHC = 117,
    ASR = 118,
    CIGNA_SELECT_GREAT_WEST_HEALTHCARE = 119,
    S_AND_S_HEALTHCARE = 120,
    NIPPON_LIFE_BENEFITS = 121,
    UPMC = 123,
    LUCENT_HEALTH_TN_AETNA = 124,
    ALLEGIANCE = 126,
    EBMS_AETNA = 127,
    HEALTH_GRAM = 128,
    DENVER_HEALTH_MEDICAL_PLAN = 130,
    HEALTH_EZ_AETNA = 131,
    PREFERRRED_ONE = 133,
    GEHA_UHC = 137,
    ALLIED_CIGNA = 138,
    CARE_FIRST_ADMINISTRATORS = 139,
    CARERISK = 140,
    BLUE_BENEFITS_ADMINISTRATORS = 141,
    AETNA_90_DEGREE_BENEFITS_CAPROCK = 142,
    CIGNA_90_DEGREE_BENEFITS_CAPROCK = 143,
    BENEFIT_AND_RISK_MANAGEMENT_SERVICES = 145,
    EBMS_CIGNA = 149,
    REGENCE_GROUP_ADMINISTRATORS = 150,
    HEALTHCARE_MANAGEMENT_ADMINISTRATORS_HMA = 152,
    HEALTH_PLANS_INC_CIGNA = 153,
    GRAVIE_ADMINISTRATIVE_SERVICES_CIGNA = 155,
    GRAVIE_ADMINISTRATIVE_SERVICES_AETNA = 156,
    SEIU_FUNDS_1199 = 157,
    LUCENT_HEALTH_TN_CIGNA = 158,
    BOON = 159,
    EVERNORTH = 160,
    ASSURANT_CIGNA = 178,
    ASSURANT_AETNA = 179,
    HEALTH_EZ_CIGNA = 180,
    MARPAI_AETNA = 181,
    MARPAI_CIGNA = 182,
    CREDENCE_BLUE_CROSS_BLUE_SHIELD = 187,
    AMERIBEN_BLUE_CROSS_BLUE_SHIELD_ARIZONA = 188,
    SSA_BLUE_CROSS_BLUE_SHIELD_ARIZONA = 190,
    INTERNATIONAL_BENEFITS_ADMINISTRATORS = 196,
    CENTIVO = 211,
    SIHO_CIGNA = 225,
    SIHO_AETNA = 226,
    POINT_32_HEALTHCARE = 245,
    TUFTS_HEALTH_PLAN_POINT_32 = 246,
    HARVARD_PILGRIM_HEALTHCARE_POINT_32 = 247,
    BLUE_CARE_NETWORK = 248,
    HEALTH_PLANS_INC_POINT_32 = 249,
    KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE = 253,
    KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA_MEDICARE_ADVANTAGE = 254,
    MEDICAL_MUTUAL_OF_OHIO = 257,
    BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS_MEDICARE_ADVANTAGE = 260,
    BLUE_CROSS_BLUE_SHIELD_MINNESOTA_MEDICARE_ADVANTAGE = 261,
    CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_MEDICARE_ADVANTAGE = 262,
    HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY_MEDICARE_ADVANTAGE = 263,
    BLUE_CROSS_BLUE_SHIELD_MINNESOTA_MEDICAID = 265,
    GEISINGER = 271,
    MODA_HEALTH = 272,
    THE_HEALTH_PLAN_WEST_VIRGINIA = 273,
    BLUE_CROSS_BLUE_SHIELD_NORTH_CAROLINA_MEDICARE_ADVANTAGE = 274,
    AMBETTER_WASHINGTON = 275,
    ANTHEM_EAP = 276,
    SANFORD_HEALTH_PLAN = 277,
    LUCENT_HEALTH_WI_CIGNA = 278,
    LUCENT_HEALTH_WI_AETNA = 279,
    LUCENT_HEALTH_HEALTHSMART = 281,
    CARELON_BEHAVIORAL_HEALTH = 282,
    OPTUM_MEDICAID_COLORADO = 284,
    OPTUM_MEDICAID_COLORADO_ROCKY_MOUNTAIN_HEALTH_PLAN_UNITED_HEALTHCARE_COMMUNITY_PLAN = 285,
    OPTUM_MEDICAID_MISSOURI_MANAGED_CARE_UNITED_HEALTHCARE_COMMUNITY_PLAN = 286,
    OPTUM_MEDICAID_OHIO_UNITED_HEALTHCARE_COMMUNITY_PLAN = 287,
    OPTUM_MEDICAID_MISSOURI = 288,
    SHARP_HEALTH_PLAN = 290,
    UNITED_HEALTHCARE_MEDICARE_ADVANTAGE = 291,
    AMBETTER_ARKANSAS = 292,
    HIGHMARK_NORTHEASTERN_NEW_YORK = 298,
    HIGHMARK_WESTERN_NEW_YORK = 299,
    QUEST_BEHAVIORAL_HEALTH = 302,
    CIGNA_PLUS_OSCAR = 303,
    OPTUM_MEDICAID_TEXAS = 305,
    SCRIPPS = 307,
    KAISER_PERMANENTE_OF_MID_ATLANTIC_ADVANTAGE = 322,
    ACS_BENEFIT_SERVICE_CIGNA = 324,
    ASCENSION = 335,
    KAISER_PERMANENTE_HAWAII = 396,
    KAISER_PERMANENTE_HAWAII_MEDICARE_ADVANTAGE = 397,
    OPTUM_MEDICAID_TEXAS_STAR_UNITED_HEALTHCARE_COMMUNITY_PLAN = 458,
    OPTUM_MEDICAID_TEXAS_STAR_PLUS_UNITED_HEALTHCARE_COMMUNITY_PLAN = 459,
    OPTUM_MEDICAID_TEXAS_CHIP_UNITED_HEALTHCARE_COMMUNITY_PLAN = 460,
    OPTUM_MEDICAID_NEW_YORK_UNITED_HEALTHCARE_COMMUNITY_PLAN = 461,
    OPTUM_MEDICAID_NEW_YORK_CHILD_HEALTH_PLUS_UNITED_HEALTHCARE_COMMUNITY_PLAN = 462,
    BLUE_CROSS_BLUE_SHIELD_RHODE_ISLAND_MEDICARE_ADVANTAGE = 463,
    BLUE_CROSS_BLUE_SHIELD_ILLINOIS_MEDICARE_ADVANTAGE = 464,
    BLUE_CROSS_BLUE_SHIELD_ILLINOIS_MEDICAID = 465,
    BLUE_CROSS_BLUE_SHIELD_ILLINOIS_MMAI = 466,
    BLUE_CROSS_BLUE_SHIELD_MICHIGAN_MEDICARE_ADVANTAGE = 468,
    OPTUM_MEDICAID_WASHINGTON_WASHINGTON_APPLE_HEALTH_UNITED_HEALTHCARE_COMMUNITY_PLAN = 470,
    OPTUM_MEDICAID_NORTH_CAROLINA = 471,
    OPTUM_MEDICAID_NORTH_CAROLINA_UNITED_HEALTHCARE_COMMUNITY_PLAN = 472,
    OPTUM_MEDICAID_VIRGINIA_CARDINAL_CARE_UNITED_HEALTHCARE_COMMUNITY_PLAN = 474,
    BLUE_CROSS_BLUE_SHIELD_SOUTH_CAROLINA_FEP = 476,
    SELECT_HEALTH_UTAH = 479,
    SENTARA_HEALTH = 480,
    SELECT_HEALTH_COLORADO = 481,
    BLUE_CROSS_BLUE_SHIELD_TEXAS_MEDICARE_ADVANTAGE = 483,
    LEADING_EDGE_ADMINISTRATORS = 484,
    AMBETTER_KANSAS = 485,
    AMBETTER_KANSAS_MEDICARE_ADVANTAGE = 487,
    AMBETTER_TEXAS = 490,
    AMBETTER_TEXAS_MEDICARE_ADVANTAGE = 491,
    AMBETTER_TEXAS_MEDICAID = 492
}


export const FrontEndCarrierIdentifierMetadata: EnumMetadata<FrontEndCarrierIdentifier> = {
    name: 'FrontEndCarrierIdentifier',
    values: {
            NONE_OF_THESE: -1,
            AETNA: 1,
            CIGNA: 3,
            EMBLEM: 4,
            EMPIRE_BLUE_CROSS_BLUE_SHIELD: 5,
            HARVARD_PILGRIM_HEALTHCARE: 6,
            MEDICAID: 7,
            MEDICARE: 8,
            MVP_HEALTHCARE: 9,
            OXFORD: 10,
            UNITED_HEALTHCARE: 11,
            KAISER_PERMANENTE_OF_COLORADO: 16,
            KAISER_PERMANENTE_OF_GEORGIA: 17,
            KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA: 18,
            KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA: 19,
            KAISER_PERMANENTE_OF_MID_ATLANTIC: 20,
            KAISER_PERMANENTE_OF_NORTHWEST: 21,
            HUMANA: 22,
            OSCAR_HEALTH: 23,
            ANTHEM_BLUE_CROSS_CALIFORNIA: 24,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_COLORADO: 25,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_CONNECTICUT: 26,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_INDIANA: 27,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_KENTUCKY: 28,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_MAINE: 29,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_MISSOURI: 30,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_NEVADA: 31,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_NEW_HAMPSHIRE: 32,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_OHIO: 33,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_VIRGINIA: 34,
            ANTHEM_BLUE_CROSS_BLUE_SHIELD_WISCONSIN: 35,
            BLUE_CROSS_BLUE_SHIELD_ARKANSAS: 36,
            BLUE_CROSS_BLUE_SHIELD_MISSISSIPPI: 37,
            BLUE_CROSS_BLUE_SHIELD_ARIZONA: 38,
            BLUE_CROSS_BLUE_SHIELD_GEORGIA: 39,
            BLUE_CROSS_BLUE_SHIELD_MICHIGAN: 40,
            BLUE_CROSS_BLUE_SHIELD_NORTH_DAKOTA: 41,
            BLUE_CROSS_BLUE_SHIELD_WYOMING: 42,
            BLUE_CROSS_BLUE_SHIELD_ALABAMA: 43,
            BLUE_CROSS_BLUE_SHIELD_HAWAII: 44,
            BLUE_CROSS_BLUE_SHIELD_ILLINOIS: 45,
            BLUE_CROSS_BLUE_SHIELD_KANSAS: 46,
            BLUE_CROSS_BLUE_SHIELD_KANSAS_CITY: 47,
            BLUE_CROSS_BLUE_SHIELD_LOUISIANA: 48,
            BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS: 49,
            BLUE_CROSS_BLUE_SHIELD_MINNESOTA: 50,
            BLUE_CROSS_BLUE_SHIELD_MONTANA: 51,
            BLUE_CROSS_BLUE_SHIELD_NEBRASKA: 52,
            BLUE_CROSS_BLUE_SHIELD_NEW_MEXICO: 53,
            BLUE_CROSS_BLUE_SHIELD_NORTH_CAROLINA: 54,
            BLUE_CROSS_BLUE_SHIELD_OKLAHOMA: 55,
            BLUE_CROSS_BLUE_SHIELD_RHODE_ISLAND: 56,
            BLUE_CROSS_BLUE_SHIELD_SOUTH_CAROLINA: 57,
            BLUE_CROSS_BLUE_SHIELD_TEXAS: 58,
            BLUE_CROSS_BLUE_SHIELD_VERMONT: 59,
            BLUE_CROSS_IDAHO: 60,
            BLUE_SHIELD_CALIFORNIA: 61,
            BLUE_CROSS_BLUE_SHIELD_PUERTO_RICO: 62,
            BLUE_CROSS_BLUE_SHIELD_TENNESSEE: 63,
            BLUE_CROSS_BLUE_SHIELD_WESTERN_NEW_YORK: 64,
            BLUE_SHIELD_NORTHEASTERN_NEW_YORK: 65,
            CAPITAL_BLUE_CROSS_PENNSYLVANIA: 66,
            CARE_FIRST_BLUE_CROSS_BLUE_SHIELD: 67,
            CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_DC: 162,
            CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_MARYLAND: 68,
            EXCELLUS_BLUE_CROSS_BLUE_SHIELD_NEW_YORK: 69,
            FLORIDA_BLUE: 70,
            HIGHMARK_BLUE_CROSS_BLUE_SHIELD_DELAWARE: 71,
            HIGHMARK_BLUE_CROSS_BLUE_SHIELD_PENNSYLVANIA: 72,
            HIGHMARK_BLUE_CROSS_BLUE_SHIELD_WEST_VIRGINIA: 73,
            HIGHMARK_BLUE_SHIELD_PENNSYLVANIA: 74,
            HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY: 75,
            INDEPENDENCE_BLUE_CROSS_PENNSYLVANIA: 76,
            PREMERA_BLUE_CROSS_WASHINGTON: 77,
            PREMERA_BLUE_CROSS_BLUE_SHIELD_ALASKA: 78,
            REGENCE_BLUE_CROSS_BLUE_SHIELD_OREGON: 79,
            REGENCE_BLUE_CROSS_BLUE_SHIELD_UTAH: 80,
            REGENCE_BLUE_SHIELD_WASHINGTON: 81,
            REGENCE_BLUE_SHIELD_IDAHO: 82,
            WELLMARK_BLUE_CROSS_BLUE_SHIELD: 83,
            GEHA_AETNA: 84,
            CSFMH: 85,
            MERITN: 86,
            ALBENS: 87,
            TRSMRK: 88,
            STDNT: 89,
            UMRWAU: 90,
            AMMEDS: 91,
            TUFTS: 92,
            WELLFLEET_CIGNA: 93,
            PRIORITY_HEALTH: 99,
            CARE_FIRST_VIRGINIA: 100,
            AVMED: 104,
            BIND: 106,
            HEALTH_PARTNERS: 107,
            GOLDEN_RULE: 108,
            BLUE_CROSS_WA_AK_PREMERA_BLUE_CROSS: 112,
            AETNA_BETTER_HEALTH_OF_FLORIDA: 113,
            MAGELLAN: 114,
            BEACON_HEALTH: 115,
            WEBTPA: 116,
            HEALTH_PLANS_INC_UHC: 117,
            ASR: 118,
            CIGNA_SELECT_GREAT_WEST_HEALTHCARE: 119,
            S_AND_S_HEALTHCARE: 120,
            NIPPON_LIFE_BENEFITS: 121,
            UPMC: 123,
            LUCENT_HEALTH_TN_AETNA: 124,
            ALLEGIANCE: 126,
            EBMS_AETNA: 127,
            HEALTH_GRAM: 128,
            DENVER_HEALTH_MEDICAL_PLAN: 130,
            HEALTH_EZ_AETNA: 131,
            PREFERRRED_ONE: 133,
            GEHA_UHC: 137,
            ALLIED_CIGNA: 138,
            CARE_FIRST_ADMINISTRATORS: 139,
            CARERISK: 140,
            BLUE_BENEFITS_ADMINISTRATORS: 141,
            AETNA_90_DEGREE_BENEFITS_CAPROCK: 142,
            CIGNA_90_DEGREE_BENEFITS_CAPROCK: 143,
            BENEFIT_AND_RISK_MANAGEMENT_SERVICES: 145,
            EBMS_CIGNA: 149,
            REGENCE_GROUP_ADMINISTRATORS: 150,
            HEALTHCARE_MANAGEMENT_ADMINISTRATORS_HMA: 152,
            HEALTH_PLANS_INC_CIGNA: 153,
            GRAVIE_ADMINISTRATIVE_SERVICES_CIGNA: 155,
            GRAVIE_ADMINISTRATIVE_SERVICES_AETNA: 156,
            SEIU_FUNDS_1199: 157,
            LUCENT_HEALTH_TN_CIGNA: 158,
            BOON: 159,
            EVERNORTH: 160,
            ASSURANT_CIGNA: 178,
            ASSURANT_AETNA: 179,
            HEALTH_EZ_CIGNA: 180,
            MARPAI_AETNA: 181,
            MARPAI_CIGNA: 182,
            CREDENCE_BLUE_CROSS_BLUE_SHIELD: 187,
            AMERIBEN_BLUE_CROSS_BLUE_SHIELD_ARIZONA: 188,
            SSA_BLUE_CROSS_BLUE_SHIELD_ARIZONA: 190,
            INTERNATIONAL_BENEFITS_ADMINISTRATORS: 196,
            CENTIVO: 211,
            SIHO_CIGNA: 225,
            SIHO_AETNA: 226,
            POINT_32_HEALTHCARE: 245,
            TUFTS_HEALTH_PLAN_POINT_32: 246,
            HARVARD_PILGRIM_HEALTHCARE_POINT_32: 247,
            BLUE_CARE_NETWORK: 248,
            HEALTH_PLANS_INC_POINT_32: 249,
            KAISER_PERMANENTE_OF_NORTHERN_CALIFORNIA_MEDICARE_ADVANTAGE: 253,
            KAISER_PERMANENTE_OF_SOUTHERN_CALIFORNIA_MEDICARE_ADVANTAGE: 254,
            MEDICAL_MUTUAL_OF_OHIO: 257,
            BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS_MEDICARE_ADVANTAGE: 260,
            BLUE_CROSS_BLUE_SHIELD_MINNESOTA_MEDICARE_ADVANTAGE: 261,
            CARE_FIRST_BLUE_CROSS_BLUE_SHIELD_MEDICARE_ADVANTAGE: 262,
            HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY_MEDICARE_ADVANTAGE: 263,
            BLUE_CROSS_BLUE_SHIELD_MINNESOTA_MEDICAID: 265,
            GEISINGER: 271,
            MODA_HEALTH: 272,
            THE_HEALTH_PLAN_WEST_VIRGINIA: 273,
            BLUE_CROSS_BLUE_SHIELD_NORTH_CAROLINA_MEDICARE_ADVANTAGE: 274,
            AMBETTER_WASHINGTON: 275,
            ANTHEM_EAP: 276,
            SANFORD_HEALTH_PLAN: 277,
            LUCENT_HEALTH_WI_CIGNA: 278,
            LUCENT_HEALTH_WI_AETNA: 279,
            LUCENT_HEALTH_HEALTHSMART: 281,
            CARELON_BEHAVIORAL_HEALTH: 282,
            OPTUM_MEDICAID_COLORADO: 284,
            OPTUM_MEDICAID_COLORADO_ROCKY_MOUNTAIN_HEALTH_PLAN_UNITED_HEALTHCARE_COMMUNITY_PLAN: 285,
            OPTUM_MEDICAID_MISSOURI_MANAGED_CARE_UNITED_HEALTHCARE_COMMUNITY_PLAN: 286,
            OPTUM_MEDICAID_OHIO_UNITED_HEALTHCARE_COMMUNITY_PLAN: 287,
            OPTUM_MEDICAID_MISSOURI: 288,
            SHARP_HEALTH_PLAN: 290,
            UNITED_HEALTHCARE_MEDICARE_ADVANTAGE: 291,
            AMBETTER_ARKANSAS: 292,
            HIGHMARK_NORTHEASTERN_NEW_YORK: 298,
            HIGHMARK_WESTERN_NEW_YORK: 299,
            QUEST_BEHAVIORAL_HEALTH: 302,
            CIGNA_PLUS_OSCAR: 303,
            OPTUM_MEDICAID_TEXAS: 305,
            SCRIPPS: 307,
            KAISER_PERMANENTE_OF_MID_ATLANTIC_ADVANTAGE: 322,
            ACS_BENEFIT_SERVICE_CIGNA: 324,
            ASCENSION: 335,
            KAISER_PERMANENTE_HAWAII: 396,
            KAISER_PERMANENTE_HAWAII_MEDICARE_ADVANTAGE: 397,
            OPTUM_MEDICAID_TEXAS_STAR_UNITED_HEALTHCARE_COMMUNITY_PLAN: 458,
            OPTUM_MEDICAID_TEXAS_STAR_PLUS_UNITED_HEALTHCARE_COMMUNITY_PLAN: 459,
            OPTUM_MEDICAID_TEXAS_CHIP_UNITED_HEALTHCARE_COMMUNITY_PLAN: 460,
            OPTUM_MEDICAID_NEW_YORK_UNITED_HEALTHCARE_COMMUNITY_PLAN: 461,
            OPTUM_MEDICAID_NEW_YORK_CHILD_HEALTH_PLUS_UNITED_HEALTHCARE_COMMUNITY_PLAN: 462,
            BLUE_CROSS_BLUE_SHIELD_RHODE_ISLAND_MEDICARE_ADVANTAGE: 463,
            BLUE_CROSS_BLUE_SHIELD_ILLINOIS_MEDICARE_ADVANTAGE: 464,
            BLUE_CROSS_BLUE_SHIELD_ILLINOIS_MEDICAID: 465,
            BLUE_CROSS_BLUE_SHIELD_ILLINOIS_MMAI: 466,
            BLUE_CROSS_BLUE_SHIELD_MICHIGAN_MEDICARE_ADVANTAGE: 468,
            OPTUM_MEDICAID_WASHINGTON_WASHINGTON_APPLE_HEALTH_UNITED_HEALTHCARE_COMMUNITY_PLAN: 470,
            OPTUM_MEDICAID_NORTH_CAROLINA: 471,
            OPTUM_MEDICAID_NORTH_CAROLINA_UNITED_HEALTHCARE_COMMUNITY_PLAN: 472,
            OPTUM_MEDICAID_VIRGINIA_CARDINAL_CARE_UNITED_HEALTHCARE_COMMUNITY_PLAN: 474,
            BLUE_CROSS_BLUE_SHIELD_SOUTH_CAROLINA_FEP: 476,
            SELECT_HEALTH_UTAH: 479,
            SENTARA_HEALTH: 480,
            SELECT_HEALTH_COLORADO: 481,
            BLUE_CROSS_BLUE_SHIELD_TEXAS_MEDICARE_ADVANTAGE: 483,
            LEADING_EDGE_ADMINISTRATORS: 484,
            AMBETTER_KANSAS: 485,
            AMBETTER_KANSAS_MEDICARE_ADVANTAGE: 487,
            AMBETTER_TEXAS: 490,
            AMBETTER_TEXAS_MEDICARE_ADVANTAGE: 491,
            AMBETTER_TEXAS_MEDICAID: 492
    }
}
