/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum PatientBillingEventStatus {
    CHARGED = 'CHARGED',
    CHARGE_FAILED = 'CHARGE_FAILED',
    REFUNDED = 'REFUNDED',
    REFUND_FAILED = 'REFUND_FAILED',
    PENDING_MANUAL_PAYMENT = 'PENDING_MANUAL_PAYMENT',
    SCHEDULED_PAYMENT = 'SCHEDULED_PAYMENT',
    UNDER_REVIEW = 'UNDER_REVIEW',
    UPCOMING_AUTO_CHARGE = 'UPCOMING_AUTO_CHARGE',
    WAIVED = 'WAIVED'
}


export const PatientBillingEventStatusMetadata: EnumMetadata<PatientBillingEventStatus> = {
    name: 'PatientBillingEventStatus',
    values: {
            CHARGED: 'CHARGED',
            CHARGE_FAILED: 'CHARGE_FAILED',
            REFUNDED: 'REFUNDED',
            REFUND_FAILED: 'REFUND_FAILED',
            PENDING_MANUAL_PAYMENT: 'PENDING_MANUAL_PAYMENT',
            SCHEDULED_PAYMENT: 'SCHEDULED_PAYMENT',
            UNDER_REVIEW: 'UNDER_REVIEW',
            UPCOMING_AUTO_CHARGE: 'UPCOMING_AUTO_CHARGE',
            WAIVED: 'WAIVED'
    }
}
