import { CSSObject } from '@emotion/react';

export const INSURANCE_CARD_ASPECT_RATIO = 3.37 / 2.125;

export const MAX_IMAGE_COMPRESSION_SIZE_IN_MB = 9.8;
export const MAX_COMPRESSION_TIMEOUT_MS = 1000 * 10;
export const BOTTOM_BUTTON_CSS: CSSObject = {
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '20px',
  paddingBottom: '24px',
  width: '100%',
  bottom: 0,
};
