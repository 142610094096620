/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum ProviderSearchTopic {
    STRESS = 'STRESS',
    FAMILY_STRUGGLES = 'FAMILY_STRUGGLES',
    CAREER_ANXIETY = 'CAREER_ANXIETY',
    FEELING_DOWN_NO_MOTIVATION = 'FEELING_DOWN_NO_MOTIVATION',
    STRUGGLE_WITH_FOCUS = 'STRUGGLE_WITH_FOCUS',
    RECENT_LOSS = 'RECENT_LOSS',
    GENDER_SEXUAL_IDENTITY = 'GENDER_SEXUAL_IDENTITY',
    DIVORCE_SEPARATION = 'DIVORCE_SEPARATION',
    RELATIONSHIPS = 'RELATIONSHIPS',
    RECENT_CHILD = 'RECENT_CHILD',
    CHALLENGES_WITH_FOOD = 'CHALLENGES_WITH_FOOD'
}


export const ProviderSearchTopicMetadata: EnumMetadata<ProviderSearchTopic> = {
    name: 'ProviderSearchTopic',
    values: {
            STRESS: 'STRESS',
            FAMILY_STRUGGLES: 'FAMILY_STRUGGLES',
            CAREER_ANXIETY: 'CAREER_ANXIETY',
            FEELING_DOWN_NO_MOTIVATION: 'FEELING_DOWN_NO_MOTIVATION',
            STRUGGLE_WITH_FOCUS: 'STRUGGLE_WITH_FOCUS',
            RECENT_LOSS: 'RECENT_LOSS',
            GENDER_SEXUAL_IDENTITY: 'GENDER_SEXUAL_IDENTITY',
            DIVORCE_SEPARATION: 'DIVORCE_SEPARATION',
            RELATIONSHIPS: 'RELATIONSHIPS',
            RECENT_CHILD: 'RECENT_CHILD',
            CHALLENGES_WITH_FOOD: 'CHALLENGES_WITH_FOOD'
    }
}
