/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum BankAccountVerificationStatus {
    PENDING_AUTOMATIC_VERIFICATION = 'PENDING_AUTOMATIC_VERIFICATION',
    PENDING_MANUAL_VERIFICATION = 'PENDING_MANUAL_VERIFICATION',
    VERIFIED = 'VERIFIED',
    VERIFICATION_FAILED = 'VERIFICATION_FAILED'
}


export const BankAccountVerificationStatusMetadata: EnumMetadata<BankAccountVerificationStatus> = {
    name: 'BankAccountVerificationStatus',
    values: {
            PENDING_AUTOMATIC_VERIFICATION: 'PENDING_AUTOMATIC_VERIFICATION',
            PENDING_MANUAL_VERIFICATION: 'PENDING_MANUAL_VERIFICATION',
            VERIFIED: 'VERIFIED',
            VERIFICATION_FAILED: 'VERIFICATION_FAILED'
    }
}
