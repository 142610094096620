import { mergeProps, useSlotId } from '@react-aria/utils';
import { AriaCheckboxProps } from '@react-types/checkbox';
import clsx from 'clsx';
import React, { useContext, useRef } from 'react';
import { useCheckbox, useCheckboxGroupItem, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import { Simplify } from 'type-fest';

import { CheckboxGroupContext } from './CheckboxGroup';
import { DATA } from './consts';
import { FieldError, FieldRoot } from './fields';
import { Validator } from './forms';
import { theme } from './theme';
import { useFieldValidity } from './useFieldValidity';
import { FormInputProps, useFormInput } from './useFormInput';
import { formatError } from './utils/errorFormatter';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type CheckboxProps = Simplify<
  {
    name?: string;
    constraints?: Validator<boolean>[];
  } & Omit<FormInputProps<string, boolean>, 'name' | 'label'> &
    PickAndConfigure<
      AriaCheckboxProps,
      | { children: 'children' }
      | { checked?: 'isSelected' }
      | { indeterminate?: 'isIndeterminate' }
    >
>;

export const Checkbox = ({
  checked,
  indeterminate, // TODO implement indeterminate state
  value,
  children,
  ...props
}: CheckboxProps) => {
  const checkboxGroupState = useContext(CheckboxGroupContext);
  //Assert conditionally optional props depending on if it's in a group or not
  if (checkboxGroupState) {
    if (!value) {
      throw new Error(
        formatError(
          "'value' prop is required on Checkbox component when it is a descendant of CheckboxGroup"
        )
      );
    }
  } else {
    if (!props.name) {
      throw new Error(
        formatError(
          "'name' prop is required on Checkbox component when it is not a descendant of CheckboxGroup"
        )
      );
    }
  }

  const rootRef = useRef<HTMLDivElement>(null);

  let isSelected;
  if (checkboxGroupState) {
    isSelected = checkboxGroupState.isSelected(value!);
  } else {
    isSelected = checked;
  }

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      ref.current?.focus();
    },
  });

  const { ariaProps, hoverProps, focusProps, isFocusVisible, isHovered } =
    useFormInput({
      ...props,
      children,
      value: value!, // Already asserted value exists or we don't need it. value! makes useCheckboxGroupItem happy
      label: children,
      name: props.name || '',
      disabled: props.disabled || checkboxGroupState?.isDisabled,
      readonly: props.readonly || checkboxGroupState?.isReadOnly,
      isSelected,
      isIndeterminate: indeterminate,
    });

  const ref = useRef<HTMLInputElement>(null);

  const options = mergeProps(ariaProps, validationProps);

  // Conditional hooks are generally considered bad, and I usually agree.
  // This is how react-spectrum does it and at the moment I don't have time to think about doing it another way.
  // it should theoretically be safe because a checkbox shouldn't be
  // going in and out of a checkbox group, so the hooks will stay consistent
  const { inputProps, isInvalid, validationErrors, labelProps } =
    checkboxGroupState
      ? useCheckboxGroupItem(options, checkboxGroupState, ref)
      : useCheckbox(options, useToggleState(options), ref);

  const errorMessageId = useSlotId([props.validation, isInvalid]);

  const Icon = inputProps.checked ? CheckedIcon : UncheckedIcon;

  return (
    <FieldRoot
      isDisabled={props.disabled}
      isReadonly={props.readonly}
      isInvalid={isInvalid}
      ref={rootRef}
      className="hlx-checkbox-root"
      {...fieldRootProps}
      {...{
        [DATA.HOVERED]: !ariaProps.isReadOnly && isHovered,
      }}
    >
      <label
        className="hlx-checkbox-label"
        {...mergeProps(hoverProps, labelProps)}
      >
        <VisuallyHidden>
          <input
            {...inputProps}
            aria-describedby={
              [
                errorMessageId,
                props['aria-describedby'],
                inputProps['aria-describedby'],
              ]
                .filter(Boolean)
                .join(' ') || undefined
            }
            {...focusProps}
            ref={ref}
          />
        </VisuallyHidden>
        <Icon
          className={clsx('hlx-checkbox-control', {
            'focus-ring': isFocusVisible,
          })}
          aria-hidden="true"
        />
        {children}
      </label>
      {!checkboxGroupState && (
        <FieldError
          className="hlx-checkbox-error"
          isInvalid={isInvalid}
          validationErrors={validationErrors}
          id={errorMessageId}
          validation={props.validation}
        />
      )}
    </FieldRoot>
  );
};

export const CheckedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <rect
        width="18"
        height="18"
        x="1"
        y="1"
        fill="currentColor"
        rx="2"
      ></rect>
      <path
        fill={theme.color.background.primary}
        fillRule="evenodd"
        d="M8.35 11.331l5.294-5.29 1.311 1.311-6.606 6.606-3.402-3.402 1.318-1.309 2.084 2.084z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const UncheckedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <rect
        width="17"
        height="17"
        x="1.5"
        y="1.5"
        stroke="currentColor"
        rx="1.5"
      ></rect>
    </svg>
  );
};

export const IndeterminateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <rect
        width="18"
        height="18"
        x="1"
        y="1"
        fill="currentColor"
        rx="2"
      ></rect>
      <path fill={theme.color.background.primary} d="M5 11h10V9H5v2z"></path>
    </svg>
  );
};
