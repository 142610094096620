import {
  CalendarDate,
  getWeeksInMonth,
  isSameDay,
  isSameMonth,
  startOfWeek,
  today,
} from '@internationalized/date';
import { useSlotId } from '@react-aria/utils';
import { Placement } from '@react-types/overlays';
import { CollectionElement } from '@react-types/shared';
import React, { Key } from 'react';
import {
  AriaButtonProps,
  AriaDialogProps,
  CalendarAria,
  DismissButton,
  FocusRing,
  HiddenSelect,
  mergeProps,
  Overlay,
  useButton,
  useCalendarCell,
  useCalendarGrid,
  useDialog,
  useFocusRing,
  useHover,
  usePopover,
  useSelect,
  VisuallyHidden,
} from 'react-aria';
import {
  CalendarState,
  DatePickerState,
  OverlayTriggerState,
  RangeCalendarState,
  useSelectState,
} from 'react-stately';

import { ListBox } from '../collections/ListBox';
import { IconButton } from '../IconButton';
import { IconCaretDown } from '../icons/CaretDown';
import { IconCaretLeft } from '../icons/CaretLeft';
import { IconCaretRight } from '../icons/CaretRight';
import { Item } from '../Select';

interface DatePickerFieldDialogProps {
  children: React.ReactNode;
}

export function DatePickerFieldDialog({
  children,
  ...props
}: DatePickerFieldDialogProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref} className="hlx-date-picker-field-dialog">
      {children}
    </div>
  );
}

// todo (nf): consolidate this with the one in ./collections/popover
interface PopoverProps {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  state: OverlayTriggerState;
  popoverRef?: React.RefObject<HTMLDivElement>;
  triggerRef: React.RefObject<Element>;
  placement?: Placement;
}
function Popover({ children, state, ...props }: PopoverProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let { popoverRef = ref } = props;
  let { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      offset: 4,
      popoverRef,
      triggerRef: props.triggerRef,
    },
    state
  );

  return (
    <Overlay>
      <div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />
      <div {...popoverProps} ref={popoverRef} style={popoverProps.style}>
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}

interface CalendarViewProps
  extends Pick<CalendarCellProps, 'isDateSignificant'> {
  state: CalendarState | RangeCalendarState;
  calendarProps: CalendarAria['calendarProps'];
  nextMonthButtonProps: AriaButtonProps;
  prevMonthButtonProps: AriaButtonProps;
  calendarRef?: React.RefObject<HTMLDivElement>;
}

export function DatePickerCalendar({
  state,
  calendarProps,
  nextMonthButtonProps,
  prevMonthButtonProps,
  calendarRef,
  isDateSignificant,
}: CalendarViewProps) {
  const monthStart = state.visibleRange.start;
  const date = monthStart.toDate(state.timeZone);

  const monthYearLabel = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    calendar: monthStart.calendar.identifier,
    timeZone: state.timeZone,
  });

  const calendar = state.visibleRange.start.calendar;

  const monthCount = calendar.getMonthsInYear(monthStart);

  const months = Array.from({ length: monthCount }, (_, i) => {
    const date = new CalendarDate(calendar, monthStart.year, i + 1, 1);

    const name = date.toDate(state.timeZone).toLocaleString('en-US', {
      month: 'long',
      calendar: calendar.identifier,
      timeZone: state.timeZone,
    });
    return {
      key: i + 1,
      label: name,
    };
  });

  const years = Array.from({ length: 13 }, (_, i) => {
    const date = new CalendarDate(
      calendar,
      state.focusedDate.year - 6 + i,
      1,
      1
    );

    return {
      key: date.year,
      label: date.toDate(state.timeZone).toLocaleString('en-US', {
        year: 'numeric',
        calendar: calendar.identifier,
        timeZone: state.timeZone,
      }),
    };
  });

  return (
    <div
      ref={calendarRef}
      {...calendarProps}
      className="hlx-date-picker-field-calendar"
    >
      <div className="hlx-date-picker-field-calendar-header">
        <VisuallyHidden>
          <h2>{monthYearLabel}</h2>
        </VisuallyHidden>
        <DatePickerSelect
          label="Month"
          selectedKey={state.focusedDate.month}
          items={months}
          onSelectionChange={(month) => {
            const date = new CalendarDate(
              calendar,
              state.focusedDate.year,
              month as number,
              1
            );

            state.setFocusedDate(date);
            state.setFocused(true);
          }}
        >
          {(item) => <Item>{item.label}</Item>}
        </DatePickerSelect>

        <DatePickerSelect
          label="Year"
          selectedKey={state.focusedDate.year}
          items={years}
          onSelectionChange={(year) => {
            const date = new CalendarDate(
              calendar,
              year as number,
              state.focusedDate.month,
              state.focusedDate.day
            );

            state.setFocusedDate(date);
            state.setFocused(true);
          }}
        >
          {(item) => <Item>{item.label}</Item>}
        </DatePickerSelect>

        <div className="hlx-date-picker-field-calendar-nav">
          <IconButton
            {...prevMonthButtonProps}
            variant="transparent"
            size="medium"
          >
            <IconCaretLeft size={16} />
          </IconButton>
          <IconButton
            {...nextMonthButtonProps}
            variant="transparent"
            size="medium"
          >
            <IconCaretRight size={16} />
          </IconButton>
        </div>
      </div>
      <CalendarGrid state={state} isDateSignificant={isDateSignificant} />
    </div>
  );
}

type SelectFieldControlProps = {
  children: React.ReactNode;
  buttonRef: React.RefObject<HTMLButtonElement>;
  isActive: boolean;
} & AriaButtonProps<'button'>;

function SelectFieldControl(props: SelectFieldControlProps) {
  const ref = props.buttonRef;
  const { buttonProps, isPressed } = useButton(props, ref);
  const { hoverProps, isHovered } = useHover({});

  return (
    <FocusRing
      focusClass="focused"
      focusRingClass="focus-ring"
      isTextInput={false}
      within={false}
    >
      <button
        type="button"
        className="hlx-datepicker-select-control"
        data-hovered={isHovered}
        data-active={isPressed || props.isActive}
        {...mergeProps(buttonProps, hoverProps)}
        ref={ref}
      >
        {props.children}
      </button>
    </FocusRing>
  );
}

interface DateSegmentValue {
  label: string;
  key: Key;
}
interface DatePickerSelectProps {
  label: string;
  selectedKey: Key;
  items: DateSegmentValue[];
  onSelectionChange: (key: Key) => void;
  children: (item: DateSegmentValue) => CollectionElement<DateSegmentValue>;
}

/**
 * This is a custom select component to be used within the DatePicker
 * dropdown.  It does not have most of the complexity that the base Select
 * component has and it is not intended to be used outside of the DatePicker.
 */
function DatePickerSelect(props: DatePickerSelectProps) {
  let state = useSelectState(props);

  let triggerRef = React.useRef<HTMLButtonElement>(null);
  let { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    triggerRef
  );

  return (
    <div className="hlx-datepicker-select" data-open={state.isOpen}>
      <VisuallyHidden>
        <div {...labelProps}>{props.label}</div>
      </VisuallyHidden>

      <HiddenSelect state={state} triggerRef={triggerRef} label={props.label} />

      <SelectFieldControl
        buttonRef={triggerRef}
        {...triggerProps}
        isActive={state.isOpen}
      >
        <span {...valueProps}>{state.selectedItem.rendered}</span>
        <IconCaretDown size={16} aria-hidden="true" />
      </SelectFieldControl>

      {state.isOpen && (
        <Popover
          state={state}
          triggerRef={triggerRef}
          isOpen={state.isOpen}
          onClose={state.close}
        >
          <div
            className="hlx-popover"
            style={{
              minWidth: 160,
            }}
          >
            <ListBox
              {...menuProps}
              state={state}
              disallowEmptySelection={true}
            />
          </div>
        </Popover>
      )}
    </div>
  );
}

interface CalendarGridProps
  extends Pick<CalendarCellProps, 'isDateSignificant'> {
  state: CalendarState | RangeCalendarState;
}

function CalendarGrid({
  state,
  isDateSignificant,
  ...props
}: CalendarGridProps) {
  let { gridProps, headerProps } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, 'en-US');

  let weekDays = React.useMemo(() => {
    let weekStart = startOfWeek(today(state.timeZone), 'en-US');
    return [...new Array(7).keys()].map((index) => {
      let date = weekStart.add({ days: index });
      let dateDay = date.toDate(state.timeZone);

      // Format the day of the week to be a short string (e.g. "Mo", "Tu", "We"). and
      // trim it to be two characters long.  Note that this might not work for all
      // languages but it works for English.
      return dateDay
        .toLocaleString('en-US', {
          weekday: 'short',
          timeZone: state.timeZone,
        })
        .slice(0, 2);
    });
  }, [state.timeZone]);

  return (
    <div className="hlx-date-picker-field-calendar-grid">
      <table {...gridProps}>
        <thead {...headerProps}>
          <tr>
            {weekDays.map((day, index) => (
              <th key={index}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
            <tr key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex)
                .map((date, i) =>
                  date ? (
                    <CalendarCell
                      key={i}
                      state={state}
                      date={date}
                      isDateSignificant={isDateSignificant}
                    />
                  ) : (
                    <td key={i} />
                  )
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export interface CalendarCellProps {
  state: CalendarState | RangeCalendarState;
  date: CalendarDate;
  /**
   * Callback that is called for each date of the calendar. If the callback returns true, then
   * that date is marked as significant with a generic description. If a string is returned, that
   * string is used as the description.
   */
  isDateSignificant?: (date: CalendarDate) => boolean | string;
}

function CalendarCell({ state, date, isDateSignificant }: CalendarCellProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isFocused,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);
  let currentMonth = state.visibleRange.start;

  let { focusProps, isFocusVisible } = useFocusRing();
  let { hoverProps, isHovered } = useHover({
    isDisabled: isDisabled || isUnavailable || state.isReadOnly,
  });

  let highlightedRange = 'highlightedRange' in state && state.highlightedRange;
  let isSelectionStart =
    isSelected && highlightedRange && isSameDay(date, highlightedRange.start);
  let isSelectionEnd =
    isSelected && highlightedRange && isSameDay(date, highlightedRange.end);

  const isSignificant = isDateSignificant?.(date);
  const significanceLabel =
    typeof isSignificant === 'string'
      ? isSignificant
      : isSignificant
      ? 'Significant date'
      : undefined;
  const significanceLabelId = useSlotId([Boolean(significanceLabel)]);

  return (
    <td {...cellProps}>
      <div
        {...mergeProps(
          buttonProps,
          hoverProps,
          focusProps,
          (significanceLabel
            ? { 'aria-describedby': significanceLabelId }
            : {}) as { 'aria-describedby'?: string }
        )}
        ref={ref}
        hidden={isOutsideVisibleRange || !isSameMonth(date, currentMonth)}
        data-selected={isSelected}
        data-is-range-selection={isSelected && 'highlightedRange' in state}
        data-selection-start={isSelectionStart}
        data-selection-end={isSelectionEnd}
        data-available={!isUnavailable}
        data-disabled={isDisabled}
        data-focused={isFocused && isFocusVisible}
        data-hovered={isHovered}
        data-significant={Boolean(significanceLabel)}
        className="hlx-date-picker-field-calendar-date"
      >
        <div className="hlx-calendar-cell-date-highlight">
          {formattedDate}
          {significanceLabel && (
            <div className="hlx-calendar-cell-date-significance-indicator">
              <VisuallyHidden id={significanceLabelId}>
                {significanceLabel}
              </VisuallyHidden>
            </div>
          )}
        </div>
      </div>
    </td>
  );
}
interface DatePickerCalendarDialogProps {
  state: DatePickerState;
  triggerRef: React.RefObject<Element>;
  dialogProps: AriaDialogProps;
  placement: Placement;
  children: React.ReactNode;
}

export function DatePickerCalendarDialog({
  state,
  triggerRef,
  dialogProps,
  children,
  placement,
}: DatePickerCalendarDialogProps) {
  return (
    <Popover state={state} triggerRef={triggerRef} placement={placement}>
      <DatePickerFieldDialog {...dialogProps}>{children}</DatePickerFieldDialog>
    </Popover>
  );
}
