import { useSlotId } from '@react-aria/utils';
import React from 'react';
import {
  AriaNumberFieldProps,
  FocusRing,
  mergeProps,
  useNumberField,
} from 'react-aria';
import { useNumberFieldState } from 'react-stately';

import { FieldError, FieldRoot } from './fields';
import { Validator } from './forms';
import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { useFieldValidity } from './useFieldValidity';
import { FormInputProps, useFormInput } from './useFormInput';

type NumberFieldProps = {
  optionalityText?: React.ReactNode;

  constraints?: Validator<number>[];
} & FormInputProps<number> &
  Pick<
    AriaNumberFieldProps,
    'formatOptions' | 'step' | 'minValue' | 'maxValue'
  >;

let currencySymbol: string;

if (typeof Intl.NumberFormat.prototype.formatToParts === 'function') {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
    .formatToParts(0)
    .find((part) => part.type === 'currency');

  currencySymbol = currency ? currency.value : '$';
} else {
  // Fallback for older browsers
  currencySymbol = '$';
}

const NumberField = (props: NumberFieldProps) => {
  const controlRef = React.useRef<HTMLInputElement>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { ariaProps, hoverProps, focusProps, isFocused } = useFormInput({
    ...props,
    isTextInput: true,
  });

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      controlRef.current?.focus();
    },
  });
  const options = mergeProps(ariaProps, validationProps, { locale: 'en-US' });

  let state = useNumberFieldState(options);

  let {
    labelProps,
    groupProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
  } = useNumberField(options, state, controlRef);

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  useAssertFormParentEffect(controlRef, 'NumberField');

  const isCurrencyField = props.formatOptions?.style === 'currency';

  return (
    <FieldRoot
      ref={rootRef}
      className="hlx-number-field-root"
      isDisabled={props.disabled}
      isReadonly={props.readonly}
      isInvalid={isInvalid}
      {...fieldRootProps}
    >
      <label className="hlx-number-field-label" {...labelProps}>
        {props.label}
      </label>
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        // isTextInput={true}
        // autoFocus={props.autoFocus}
      >
        <div
          className="hlx-number-field-control-group"
          {...mergeProps(groupProps, hoverProps)}
        >
          {isCurrencyField && (
            <span
              className="hlx-number-field-currency-symbol"
              style={{
                display:
                  state.inputValue !== '' || isFocused ? 'block' : 'none',
              }}
            >
              {currencySymbol}
            </span>
          )}
          <input
            className="hlx-number-field-control"
            ref={controlRef}
            {...mergeProps(
              (props.optionalityText
                ? { 'aria-describedby': optionalityId }
                : {}) as { 'aria-describedby'?: string },
              inputProps,
              focusProps,
              isCurrencyField
                ? {
                    value: currencySymbol
                      ? state.inputValue.replace(currencySymbol, '')
                      : state.inputValue,
                  }
                : undefined
            )}
          />
          {props.name && (
            <input
              type="hidden"
              name={props.name}
              value={isNaN(state.numberValue) ? '' : state.numberValue}
            />
          )}
        </div>
      </FocusRing>
      {props.optionalityText && (
        <div id={optionalityId} className="hlx-number-field-optionality-text">
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-number-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      <FieldError
        className="hlx-number-field-error"
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        {...errorMessageProps}
      />
    </FieldRoot>
  );
};

export { NumberField };
