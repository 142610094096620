/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum WrapNetwork {
    BCBS_MA = 'BCBS_MA',
    BCBS_NJ = 'BCBS_NJ',
    INDEPENDENCE_BC = 'INDEPENDENCE_BC'
}


export const WrapNetworkMetadata: EnumMetadata<WrapNetwork> = {
    name: 'WrapNetwork',
    values: {
            BCBS_MA: 'BCBS_MA',
            BCBS_NJ: 'BCBS_NJ',
            INDEPENDENCE_BC: 'INDEPENDENCE_BC'
    }
}
