/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum PatientAgeGroup {
    INFANTS_TODDLERS = 'INFANTS_TODDLERS',
    CHILDREN = 'CHILDREN',
    ADOLESCENTS = 'ADOLESCENTS',
    ADULTS = 'ADULTS',
    SENIORS = 'SENIORS'
}


export const PatientAgeGroupMetadata: EnumMetadata<PatientAgeGroup> = {
    name: 'PatientAgeGroup',
    values: {
            INFANTS_TODDLERS: 'INFANTS_TODDLERS',
            CHILDREN: 'CHILDREN',
            ADOLESCENTS: 'ADOLESCENTS',
            ADULTS: 'ADULTS',
            SENIORS: 'SENIORS'
    }
}
