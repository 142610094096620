import React from 'react';
import { mergeProps } from 'react-aria';

import { FieldValidation } from './Form';
import { ValidationState } from './forms';

interface FieldErrorProps extends React.ComponentProps<'div'> {
  className?: string;
  isInvalid: boolean;
  validationErrors: string[];
  validation: ValidationState | undefined;
}

export function FieldError({
  isInvalid,
  validationErrors,
  validation,
  ...props
}: FieldErrorProps) {
  if (!isInvalid) {
    return null;
  }

  let content =
    validationErrors[0] ??
    (validation?.validity === 'invalid' ? validation.message : undefined);

  return <div {...props}>{content}</div>;
}

interface FieldRootProps {
  children: React.ReactNode;
  className?: string;
  isReadonly?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  style?: React.CSSProperties;
}

function FieldRoot(
  { isReadonly, isDisabled, isInvalid, ...props }: FieldRootProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      {...mergeProps(props, {
        className: 'hlx-field-root',
      })}
      data-hlx-readonly={isReadonly}
      data-hlx-disabled={isDisabled}
      data-hlx-validation={isInvalid ? 'invalid' : undefined}
    />
  );
}

const _FieldRoot = React.forwardRef(FieldRoot);

export { _FieldRoot as FieldRoot };
